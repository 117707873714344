import React, { useRef, useState } from 'react';
import '../../utility/helper'
import logo from '../../images/logo.png';


export default function MessageModal(props) {

    const type = props.type;
    const title = props?.title;
    const error = props.errorMessage;
    const success = props.successMessage;
    const message = props.message;
    const buttonStatus = ("buttonStatus" in props) ? props.buttonStatus : true;
    console.log("props+++++++++++", props);
    const onCancel = async () => {
        props.onHide(true);
    }

    return (
        <>
            <div className={`header-content`}>
                {/* <p className='font-weight-700 text-left text-light' style={{ fontSize: '18px' }}>{title}</p> */}
                    <img className="form-image" src={logo} alt="" />
                    <p className="form-title pt-4">ClutchTime</p>               
            </div>
            {
                (type == "error") ?
                    <p className="text-danger text-center" style={{ fontSize: '18px' }}>{error}</p>
                    :
                    (type == "message") ?
                        <p className="text-white text-center" style={{ fontSize: '18px' }}>{message}</p>
                        :
                        <p className="text-success text-center" style={{ fontSize: '18px' }}>{success}</p>
            }
            {
                (buttonStatus)&&
                    <div class="d-flex flex-row align-item-center justify-content-around"  style={{ marginTop: '30px' }}>
                        {/* <button onClick={() => { onCancel() }} style={{ width: '30%', paddingVertical: '15px' }} type="button"><span className='form-btn'>Okay</span></button> */}
                        <button className='btn' onClick={() => { onCancel() }}>
                            <span className='' style={{ fontSize: '16px' }}>Okay</span>
                        </button>
                    </div>
            }
        </>
    );

}

