import React, {useState} from 'react';
import '../../utility/helper'
import {updateDocument,updateFirebaseDocument, updateFirestoreDocument} from "../../utility/helper";

import mail from "../../images/mail.svg";
import twitterIcon from "../../images/twitter.png";
import facebookIcon from "../../images/facebook.svg";
import twitchIcon from "../../images/twitch.png";
import youtubeIcon from "../../images/youtube.png";
import userIcon from "../../images/user.svg";
import {useLoader} from "../../contexts/loaderContext";
import {auth, db, storage} from "../../firebase/firebase";
import BackButton from "../../components/backButton";
import {useAuthState, EmailAuthProvider } from "react-firebase-hooks/auth"
import {useStateValue} from '../../contexts/StateProvider';

export default function EditProfile(props) {

    const {setLoader} = useLoader();
    const [user] = useAuthState(auth)
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [{userData},dispatch] = useStateValue()



    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = () => { 
        setFile('')
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        setFile(event.target.files[0])
    };


    const handleSubmit = async (e) => {
        setError('')
        var formData = {
            userName: e.target.userName.value,
            email: e.target.email.value,
            // facebook: e.target.facebook.value.trim(),
            playstationTag: e.target.playstationGamerTag.value,
            xboxTag: e.target.xboxGamerTag.value,
            nintendoTag: e.target.nintendoGamerTag.value,
            // twitter: e.target.twitter.value.trim(),
            twitchlink: e.target.twitchlink.value.trim(),
            youtubelink: e.target.youtubelink.value.trim(),
        };
        

        setLoading(true)
        e.preventDefault()

        // Check if username and email already exists
        var updationData = {};
        
        // const usernameRef = await db.collection('Users').where('userGamerTag', '==', formData.userName).get();
        // const emailRef = await db.collection('Users').where('userEmail', '==', formData.email).get();


        const users = await db.collection('User').get();
                
        var username_exists_satus = false;
        var email_exists_status = false;

        var xbox_exists_status = false;
        var playstation_exists_status = false;
        var nintendo_exists_status = false;

        users.forEach(usr=>{
            const usrData = usr.data(); 

            if(user.uid != usrData.objectId){
                
                if(formData.playstationTag.trim() != "" && usrData.playstationGamerTag && usrData.playstationGamerTag.toLowerCase() == formData.playstationTag?.toLowerCase()){
                    playstation_exists_status = true;
                }
                else if(formData.playstationTag.trim() != "" && usrData.gamerTag && usrData.gamerTag.toLowerCase() == formData.playstationTag?.toLowerCase()){
                    playstation_exists_status = true;
                }

                if(formData.xboxTag.trim() != "" && usrData.xboxGamerTag && usrData.xboxGamerTag.toLowerCase() == formData.xboxTag?.toLowerCase()){
                    xbox_exists_status = true;
                }
                else if(formData.xboxTag.trim() != "" && usrData.gamerTag && usrData.gamerTag.toLowerCase() == formData.xboxTag?.toLowerCase()){
                    xbox_exists_status = true;
                }
                
                if(formData.nintendoTag.trim() != "" && usrData.nintendoGamerTag && usrData.nintendoGamerTag.toLowerCase() == formData.nintendoTag?.toLowerCase()){
                    nintendo_exists_status = true;
                }
                else if(formData.nintendoTag.trim() != "" && usrData.gamerTag && usrData.gamerTag.toLowerCase() == formData.nintendoTag?.toLowerCase()){
                    nintendo_exists_status = true;
                }
                
            }

            if(usrData.userName && usrData.userName.toLowerCase() == formData.userName?.toLowerCase()){
                username_exists_satus = true;
            }
            else if(usrData.gamerTag && usrData.gamerTag.toLowerCase() == formData.userName?.toLowerCase()){
                username_exists_satus = true;
            }

            if(usrData.email?.toLowerCase() == formData.email?.toLowerCase()){
                email_exists_status = true;
            }
        })

        if(playstation_exists_status){
            setError('PlaystationGamerTag is already exists.')
            setLoading(false);
            return false;
        }
        if(xbox_exists_status){
            setError('XboxGamerTag is already exists.')
            setLoading(false);
            return false;
        }
        if(nintendo_exists_status){
            setError('NintendoGamerTag is already exists.')
            setLoading(false);
            return false;
        }
        
        console.log("Going to save datas++++++++++");
        // ===================== Complete Code for update username ===================
        // if(usernameRef.empty &&  formData.userName !== userData?.gamerTag){

        //     try{

        //         user.updateProfile({displayName: formData.userName}).then(() => {
    
        //             //Update user Info
        //             // updateDocument('Users', user.uid, formData)
        //             updateFirestoreDocument('User', user.uid, {gamerTag: formData.userName})
        //             .then(() => {
        
        //                 //Update userData balance in firebase userData document                 
        //                 updateFirebaseDocument('Users', user.uid, {userGamerTag: formData.userName})
        //                 .catch(e => {
        //                     console.log(e);
        //                 })
        //                 userData.gamerTag = formData.userName;
        //                 // Object.assign(userData, {userName: formData.userName})
        //                 dispatch({
        //                     type:"SET_USER_DATA",
        //                     userData
        //                 })
                        
        //             })
        //             .catch(e => {
        //                     console.log(e)
        //                     setError('There is an error to update profile.')
        //                 }
        //             )
    
        //             // Update email in Firebase Auth
        //             // auth.currentUser.updateEmail(formData.email)
        //             //     .then(() =>{
        //             //         setLoading(false)
        //             //     })
        //             //     .catch(err =>{
        //             //         console.log(err.message)
        //             //     })
        //         }).catch(e => console.log(e));
        //     } catch (e) {
        //         setLoader(false)
        //         console.log(e.message)
        //         console.log('error')
        //         setLoading(false)
        //     }
        // }
        // else if(!usernameRef.empty &&  formData.userName !== userData?.gamerTag){
        //     setError('Username not available');

        //     setLoading(false);
        // }

        // ===================== Complete Code for update email ===================
        // if(emailRef.empty &&  formData.email !== userData?.email){
        //     try{

        //         user.updateProfile({email: formData.email}).then(() => {
    
        //             //Update user Info
        //             // updateDocument('Users', user.uid, formData)
                    
        //             // Update email in Firebase Auth
        //             // var cred = firebase.auth.EmailAuthProvider.credential(user.email, '12345678');
        //             // user.reauthenticateWithCredential("12345678").then(() => {
        //             auth.signInWithEmailAndPassword(user.email, '12345678').then((task)=>{
        //                 updateFirestoreDocument('User', user.uid, {email: formData.email})
        //                 .then(() => {
            
        //                     //Update userData balance in firebase userData document
        //                     updateFirebaseDocument('Users', user.uid, {userEmail: formData.email})
        //                     .catch(e => {
        //                         console.log(e);
        //                     })
                            
        //                     // Object.assign(userData, {email: formData.email})
        //                     userData.email = formData.email;
        //                     dispatch({
        //                         type:"SET_USER_DATA",
        //                         userData
        //                     })
                            
        //                 })
        //                 .catch(e => {
        //                         console.log(e)
        //                         setError('There is an error to update profile.')
        //                     }
        //                 )
        //                 auth.currentUser.updateEmail(formData.email)
        //                     .then(() =>{
        //                         setLoading(false)
        //                     })
        //                     .catch(err =>{
        //                         console.log("Update email error+++++++++++++++++++++++++",err.message)
        //                     })
        //             }).catch((error) => { console.log(error); });
        //         }).catch(e => console.log(e))
        //     } catch (e) {
        //         setLoader(false)
        //         console.log(e.message)
        //         console.log('error')
        //         setLoading(false)
        //     }
        // }
        // else if(!emailRef.empty &&  formData.email !== userData?.email){
        //     setError('Email already in use');
        //     setLoading(false);
        // }

        if(file && file.size){
            try{
                storage.ref(`users/${user.uid}/profilePhoto`).put(file).then(snapshot => {
                    snapshot.ref.getDownloadURL().then((url) => {
                        user.updateProfile({ photoURL: url }).then(() => {
                            
                            // Object.assign(formData, updatedInfo)
                            // //Update user Info
                            // // updateDocument('Users', user.uid, formData)
                            updateFirestoreDocument('User', user.uid, {userProfileImageUrl: url})
                            .then(() => {
                
                                //Update userData balance in firebase userData document
                                updateFirebaseDocument('Users', user.uid, {userProfileImageUrl: url})
                                .catch(e => {
                                    console.log(e);
                                })
                                userData.userProfileImageUrl = url;
                                // Object.assign(userData, {userProfileImageUrl: url});
                                dispatch({
                                    type:"SET_USER_DATA",
                                    userData
                                })
                                props.profileUpdated();
                            })
                            .catch(e => {
                                    console.log(e)
                                    setError('There is an error to update profile.')
                                }
                            )
                        });
                    })
                    setFile('')
                }) .catch(error => {
                    setLoading(false)
                    console.log(error.message)
                })
            } catch (e) {
                setLoader(false)
                console.log(e.message)
                console.log('error')
                setLoading(false)
            }
        }
        // userData.facebook = formData.facebook;
        // userData.twitter = formData.twitter;
        userData.twitchlink = formData.twitchlink;
        userData.youtubelink = formData.youtubelink;
        userData.playstationGamerTag = formData.playstationTag;
        userData.xboxGamerTag = formData.xboxTag;
        userData.nintendoGamerTag = formData.nintendoTag;
        // console.log("Data =============", updationData);
        updateFirestoreDocument('User', user.uid, userData)
        .then(() => {
            
            //Update userData balance in firebase userData document
            updateFirebaseDocument('Users', user.uid, {userTwitchChannelLink: formData.twitchlink, userYouTubeChannelLink: formData.youtubelink, playstationGamerTag: formData.playstationTag, xboxGamerTag: formData.xboxTag, nintendoGamerTag: formData.nintendoTag})
            .catch(e => {
                console.log(e);
            })
            // Object.assign(userData, updationData)
            dispatch({
                type:"SET_USER_DATA",
                userData
            })

            props.profileUpdated();
            
        })
        .catch(e => {
                console.log(e)
                setError('There is an error to update profile.')
            }
        )

        setLoading(false)


    }

    const playStationStyle =  (userData.playstationGamerTag && userData.playstationGamerTag.trim() != "")? {backgroundImage: `url(${userIcon})`, cursor: "not-allowed", color: 'slategrey', backgroundBlendMode: 'overlay'} : {backgroundImage: `url(${userIcon})`};

    const xboxGamerTagStyle =  (userData.xboxGamerTag && userData.xboxGamerTag.trim() != "")? {backgroundImage: `url(${userIcon})`, cursor: "not-allowed", color: 'slategrey', backgroundBlendMode: 'overlay'} : {backgroundImage: `url(${userIcon})`};

    const nintendoStyle =  (userData.nintendoGamerTag && userData.nintendoGamerTag.trim() != "")? {backgroundImage: `url(${userIcon})`, cursor: "not-allowed", color: 'slategrey', backgroundBlendMode: 'overlay'} : {backgroundImage: `url(${userIcon})`};

    return (
        <>
            <form className="form" onSubmit={handleSubmit}>
                <p className="text-danger">{error}</p>

                <div className="input-group">
                    <div className={`ml-5 mb-3 d-flex text-light align-items-center`}>
                        <svg onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                             viewBox="0 0 450 450">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_15" data-name="Rectangle 15" width="150" height="150"
                                          transform="translate(230 500)" fill="#18ff00"/>
                                </clipPath>
                            </defs>
                            <g id="camera" transform="translate(-80 -350)">
                                <g id="Rectangle_14" data-name="Rectangle 14" transform="translate(80 350)"
                                   fill="#13161a" stroke="#18ff00" stroke-width="5" stroke-dasharray="20">
                                    <rect width="450" height="450" rx="225" stroke="none"/>
                                    <rect x="2.5" y="2.5" width="445" height="445" rx="222.5" fill="none"/>
                                </g>
                                <g id="Mask_Group_9" data-name="Mask Group 9" clip-path="url(#clip-path)">
                                    <g id="camera-line_11_" data-name="camera-line (11)" transform="translate(230 500)">
                                        <path id="Path_16" data-name="Path 16" d="M0,0H150V150H0Z" fill="none"/>
                                        <path id="Path_17" data-name="Path 17"
                                              d="M61.425,31.25l-12.5,12.5H25v75H125v-75H101.075l-12.5-12.5ZM56.25,18.75h37.5l12.5,12.5h25a6.25,6.25,0,0,1,6.25,6.25V125a6.25,6.25,0,0,1-6.25,6.25H18.75A6.25,6.25,0,0,1,12.5,125V37.5a6.25,6.25,0,0,1,6.25-6.25h25ZM75,112.5a34.375,34.375,0,1,1,34.375-34.375A34.375,34.375,0,0,1,75,112.5ZM75,100A21.875,21.875,0,1,0,53.125,78.125,21.875,21.875,0,0,0,75,100Z"
                                              fill="#18ff00"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span className={`ml-5`}>{file && file.name && 'Image selected successfully'}</span>

                    </div>

                    <input
                        name={`photo`}
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display: 'none'}}
                    />
                    <input  type={`text`} style={{display: 'none'}} value={user.userProfileImageUrl}/>
                    <input required defaultValue={user.email} name="email" style={{backgroundImage: `url(${mail})`, cursor: "not-allowed", color: 'slategrey', backgroundBlendMode: 'overlay'}} type="Email" placeholder="Email" disabled="true" />

                    <input required defaultValue={user.displayName || userData.gamerTag} name="userName" style={{backgroundImage: `url(${userIcon})`, cursor: "not-allowed", color: 'slategrey', backgroundBlendMode: 'overlay'}} type="text" placeholder="Username" disabled/>

                    <input defaultValue={(userData.playstationGamerTag) ? userData.playstationGamerTag : ""} name="playstationGamerTag" style={playStationStyle} type="text" placeholder="playstationGamerTag" disabled={(userData.playstationGamerTag && userData.playstationGamerTag.trim() != "")}/>

                    <input defaultValue={(userData.xboxGamerTag) ? userData.xboxGamerTag : ""} name="xboxGamerTag" style={xboxGamerTagStyle} type="text" placeholder="xboxGamerTag" disabled={(userData.xboxGamerTag && userData.xboxGamerTag.trim() != "")}/>

                    <input defaultValue={(userData.nintendoGamerTag) ? userData.nintendoGamerTag : ""} name="nintendoGamerTag" style={nintendoStyle} type="text" placeholder="nintendoGamerTag" disabled={(userData.nintendoGamerTag && userData.nintendoGamerTag.trim() != "")}/>
                    {/* <input  name="facebook" style={{backgroundImage: `url(${facebookIcon})`}} defaultValue={userData?.facebook}  type="text"
                            placeholder="Facebook"/>
                    <input  name="twitter" style={{backgroundImage: `url(${twitterIcon})`}} defaultValue={userData?.twitter} type="text"
                            placeholder="Twitter"/> */}
                    <input  name="twitchlink" style={{backgroundImage: `url(${twitchIcon})`}} defaultValue={userData?.twitchlink}  type="text"
                            placeholder="twitchlink Channel URL"/>
                    <input  name="youtubelink" style={{backgroundImage: `url(${youtubeIcon})`}} defaultValue={userData?.youtubelink} type="text"
                            placeholder="youtubelink Channel URL"/>

                </div>

                <button disabled={loading} style={{background : loading ? '#ffffff':''}} type="submit"><span
                    className='form-btn'>{loading ? 'Updating...' : 'Update'}</span></button>
            </form>
        </>
    );

}

