import React, {forwardRef, useEffect, useRef, useState} from 'react';
import Search from "../search/search";
import Header from "../header/header";
import Footer from "../footer/footer";
import './events.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Category from "../../components/category";
import Card from "../../components/card";
import MobileNavbar from "../../components/mobileNavbar";
import {auth, db, realTimeDatabase} from "../../firebase/firebase";
import {useEvent} from "../../contexts/eventsContext";
import {useLoader} from "../../contexts/loaderContext";
import {useStateValue} from '../../contexts/StateProvider';
import {useList} from "react-firebase-hooks/database";
import {useAuthState} from "react-firebase-hooks/auth";
import {useForm} from "../../contexts/formContext";
import {pushRealTimeData, updateFirebaseDocument, updateFirestoreDocument} from "../../utility/helper";
import {Modal} from "react-bootstrap";
import BackButton from "../../components/backButton";
import dropdown from "../../images/sort.png";
import Option from "../../components/option";
import EventOptions from "../../components/eventOptions";
import Switch from "@material-ui/core/Switch";
import plus from "../../images/plus.svg";
import calendar from "../../images/calender.png";
import back_arrow from "../../images/back_arrow.svg";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import logo from '../../images/logo.png';

function EventForm(props) {
	const [{selectedParticipants}, dispatch] = useStateValue()
    const [user] = useAuthState(auth)
    const [{userData}] = useStateValue()
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)
    const {setLoader, loader} = useLoader()
    const [formOptions, setFormOptions] = useState([])
    const [maxParticipants, setMaxParticipants] = useState([])
    const [eventTotalPrizes, setEventTotalPrizes] = useState(0)
    const [options, setOptions] = useState({});
    const [ruleSetOptions, setRuleSetOptions] = useState([]);
    const [state, setState] = React.useState({checkedB: true});
    // const [startDate,setStartDate] = useState(0);
    var today = new Date()
    const [endDate, setEndDate] = useState(today.setDate(today.getDate() + 1));
    const [startDate, setStartDate] = useState(new Date());
    const [eventsNames , setEventsNames] = useState([]);
    const [gameCoverArt, setGameCoverArt] = useState(null);

    const scrollView = useRef();
    const exhibitionNames = []
    const quarterLengths = ['6 Minutes', '7 Minutes', '8 Minutes', '9 Minutes', '10 Minutes', '11 Minutes', '12 Minutes']
    var totalA, totb, totc, totd, alltot, altval, getslider;
    var chktot = 100;
    var scrore = 101;
    const CustomDate = forwardRef(({value, onClick}, ref) => (
        <>
            <div className={`date pointer d-flex align-items-center justify-content-center`} onClick={onClick} ref={ref}
                 name="date" style={{backgroundImage: `url(${calendar})`}}
            >
                {value}
            </div>
        </>

    ));
    // Validates the starting and ending dates
    // useEffect(() => {
    //     console.log("Effect called+++++");
    //     if (endDate - startDate < 0) {
    //         console.log("in if+++++");

    //         setStartDate(new Date())
    //         setEndDate(new Date().setDate(today.getDate() + 1))


    //     } else {
    //         setError('')
    //     }
    //     return () => null

    // }, [startDate, endDate])

    useEffect(() => {
        if (error) {
            // Scrolls to the error message
            scrollView.current.scrollIntoView({behavior: 'smooth'})
            setLoading(false)


        }
    }, [error])

    const changeStartDate = (sDate)=>{
        setStartDate(sDate);
        setEndDate(new Date().setDate(sDate.getDate() + 1));
    }

    const changeEndDate = (eDate)=>{
        if (eDate - startDate < 0) {
            setEndDate(new Date().setDate(eDate.getDate() + 1))
        }
        else{
            setEndDate(eDate);
        }
    }

    // Populates event sport field on the event form
    // with firestore data
    useEffect(() => {


        const collectionList = []

        db.collection('Games').get().then(snapshot => {


            snapshot.forEach(doc => {
                const data = doc.data()
                collectionList.push(data)

            })


        })
        setFormOptions(collectionList)


    }, [])
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
        if (event.target.checked) {
            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';

        } else {
            totalA = document.getElementById('range-1').value = 0
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 0
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 0
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 0
            document.getElementById(`range-4-value`).innerText = totd + '%';
        }
    };

    function adjustSlider(e) {

        // Get the sliders Id
        document.getElementById(`${e.target.id}-value`).innerText = e.target.value + '%';

        getslider = e.target.id;
        // setSlider(e.target.ariaValueNow)
        // console.log(slider)

        //Gather all slider values
        totalA = parseInt(document.getElementById('range-1').value);
        totb = parseInt(document.getElementById('range-2').value);
        totc = parseInt(document.getElementById('range-3').value);
        totd = parseInt(document.getElementById('range-4').value);

        alltot = totalA + totb + totc + totd;

        //check sliders total if greater than 100 and re-update slider
        if (alltot > chktot) {
            if (getslider === "range-1") {
                altval = chktot - totb - totc - totd;
                document.getElementById('range-1').value = altval
                document.getElementById(`range-1-value`).innerText = altval + '%';
                // setSlider(altval);

            }
            if (getslider === "range-2") {
                altval = chktot - totalA - totc - totd;
                document.getElementById('range-2').value = altval
                document.getElementById(`range-2-value`).innerText = altval + '%';


            }
            if (getslider === "range-3") {
                altval = chktot - totalA - totb - totd;
                // setSlider(altval);
                document.getElementById('range-3').value = altval
                document.getElementById(`range-3-value`).innerText = altval + '%';

            }
            if (getslider === "range-4") {
                altval = chktot - totalA - totb - totc;
                document.getElementById('range-4').value = altval
                document.getElementById(`range-4-value`).innerText = altval + '%';


                // setSlider(altval);
            }
        }
    }


    async function maximumParticipants() {
        var eventStyle = document.getElementById("event-style");
        var eventSport = document.getElementById("event-sport");
        var selectedOption = eventStyle.options[eventStyle.selectedIndex].text;
        var selectedEventSport = eventSport.options[eventSport.selectedIndex].text;
        const seasonParticipants = []

        for (let j = 2; j < 31; j++) {
            seasonParticipants.push(j)
        }
        if(selectedOption === 'Exhibition') {

            document.getElementById('range-1').value = 100;
            document.getElementById(`range-1-value`).innerText = 100 + '%';

            document.getElementById('range-2').value = 0;
            document.getElementById(`range-2-value`).innerText = 0 + '%';
            
            document.getElementById('range-3').value = 0;
            document.getElementById(`range-3-value`).innerText = 0 + '%';

            document.getElementById('range-4').value = 0
            document.getElementById(`range-4-value`).innerText = 0 + '%';
            await setMaxParticipants(['2']);
        }
        else if(selectedOption === 'Season' && selectedEventSport === 'Madden'){

            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';
            await setMaxParticipants([...seasonParticipants, ...[31, 32]])

        }
        else if(selectedOption === 'Season' && selectedEventSport === 'NBA2K'){
            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';
            await setMaxParticipants(seasonParticipants)
        }    
        else if(selectedOption === 'Tournament'){
            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';
            await setMaxParticipants(['4', '8', '16', '32', '64'])  
        } 
        else if(selectedOption === 'Franchise' && selectedEventSport === 'Football'){

            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';
            await setMaxParticipants([...seasonParticipants, ...[31, 32]])
        }
        else if(selectedOption === 'Franchise' && selectedEventSport === 'NBA2K'){
            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';
            await setMaxParticipants(seasonParticipants)
        }
        else{
            totalA = document.getElementById('range-1').value = 40
            document.getElementById(`range-1-value`).innerText = totalA + '%';

            totb = document.getElementById('range-2').value = 25
            document.getElementById(`range-2-value`).innerText = totb + '%';

            totc = document.getElementById('range-3').value = 20
            document.getElementById(`range-3-value`).innerText = totc + '%';

            totd = document.getElementById('range-4').value = 15
            document.getElementById(`range-4-value`).innerText = totd + '%';
            await setMaxParticipants(seasonParticipants)
        }
       
    }

    async function onChangeBattleType(e){
        await setRuleSetOptions(options.RuleSet[e.target.value]);
    }

    function totalPrize(e) {
        if (parseInt(e.target.value) < 0) {
            e.target.value = parseInt(e.target.value) * -2 + parseInt(e.target.value)
        }
    
        var o = document.getElementById("event-participants");
        var selectedOption = o.options[o.selectedIndex].text;
        const entryFee = parseInt(selectedOption) * e.target.value
        const percentage = entryFee * 7.5 / 100 
        const computation = entryFee - percentage;
        {
            computation < 0 ? setEventTotalPrizes(0) : setEventTotalPrizes(computation?.toFixed(2))
        }

    }

    async function createEvent(e) {
        e.preventDefault()
        setError('')
        //Set loading state
        setLoading(true)
        const entryFee = parseInt(e.target.EventEntryFee.value);
        const deductionRate = 7.5;
        const priceProgressFee = entryFee - (entryFee * deductionRate/100);

        var userGamerTag = userData.gamerTag;

        if(e.target.ExhibitionConsole.value.indexOf('PlayStation', 0) >= 0 && userData.playstationGamerTag && userData.playstationGamerTag != ""){
            userGamerTag = userData.playstationGamerTag;
        }
        else if(e.target.ExhibitionConsole.value.indexOf('Xbox', 0) >= 0 && userData.xboxGamerTag && userData.xboxGamerTag != ""){
            userGamerTag = userData.xboxGamerTag;
        }
        else if(e.target.ExhibitionConsole.value.indexOf('Nintendo', 0) >= 0 && userData.nintendoGamerTag && userData.nintendoGamerTag != ""){
            userGamerTag = userData.nintendoGamerTag;
        }
        
        var formData = {

            ExhibitionName: e.target.EventName.value,
            ExhibitionGame: e.target.Eventsport.value,
            ExhibitionGameType: options.gameType,
            ExhibitionConsole: e.target.ExhibitionConsole.value,
            ExhibitionStyle: e.target.EventStyle.value,
            ExhibitionStartDate: Math.floor(startDate / 1),
            ExhibitionEndDate: Math.floor(endDate / 1),
            ExhibitionCurrentParticipants: 1,
            ExhibitionMaximumParticipants: parseInt(e.target.EventMaximumParticipants.value),
            ExhibitionEntryFee: parseInt(e.target.EventEntryFee.value),
            ExhibitionTotalPrizes: parseFloat(e.target.EventTotalPrizes.value),
            ExhibitionTimestamp: Date.now(),
            ExhibitionCommissioner: userGamerTag,
            ExhibitionCommissionerId: user.uid,
            ExhibitionCommissionerProfile: userData?.userProfileImageUrl,
            ExhibitionFirstPlacePercent: parseInt(e.target.range_1.value),
            ExhibitionSecondPlacePercent: parseInt(e.target.range_2.value),
            ExhibitionThirdPlacePercent: parseInt(e.target.range_3.value),
            ExhibitionFourthPlacePercent: parseInt(e.target.range_4.value),
            ExhibitionSecondCommissioner: 'Fantasy Sports',
            ExhibitionSecondCommissionerId: 'None',
            ExhibitionSecondCommissionerProfile: 'None',
            ExhibitionClutchTimePrizeTier: document.querySelector("[name='checkedB']").checked,
            prizeProgress:priceProgressFee,
            ExhibitionGameCoverArt: gameCoverArt
        }

        if(options.gameType == "Sport"){
            formData.ExhibitionDifficulty = e.target.EventDifficulty.value;
            formData.ExhibitionQuarterLength = e.target.QuarterLengths.value;
        }
        else if(options.gameType == "Pokemon VS"){
            formData.ExhibitionBattleType = e.target.BatteType.value;
            formData.ExhibitionRuleSet = e.target.RuleSet.value;
        }


        await realTimeDatabase.ref('Exhibitions')
            .get()
            .then( snapshot =>{
                snapshot.forEach( doc => {
                    if(doc.val().ExhibitionName !== undefined || doc.val().ExhibitionName !== null)
                        exhibitionNames.push(doc.val().ExhibitionName)
                })

                setEventsNames(exhibitionNames)
                // Event name validator
                const isAvailable = exhibitionNames.find( name =>{
                    return name?.toLowerCase() === formData.ExhibitionName?.toLowerCase();
                })
                if(!isAvailable){
                    try {
                        if (parseInt(userData.userBalance) >= parseInt(formData.ExhibitionEntryFee) && formData.ExhibitionEntryFee >= 5) {

                            //Remaining balance deducted from the user balance  after Joining event
                            const remainingBalance = parseInt(userData.userBalance) - parseInt(formData.ExhibitionEntryFee)
                            updateFirestoreDocument('User', user.uid, {userBalance: remainingBalance})
                                .then(() => {
                                        //Update userBalance in  firebase
                                        updateFirebaseDocument('Users', user.uid, {userBalance: remainingBalance}).then(()=>{
                                            userData.userBalance = remainingBalance;
                                            dispatch({
                                                type:"SET_USER_DATA",
                                                userData
                                            })
                                            
                                            pushRealTimeData('Exhibitions', formData).then((docRef) => {
                                                //Update event document with an Id field that matches its reference id
                                                //key property returns the documents reference Id
                                                updateFirebaseDocument('Exhibitions', docRef.key, {id: docRef.key})
                                                .then(() => {
                                                        //Remove user balance property from user object from the auth context
                                                        var newUserObj = userData
                                                        delete newUserObj['balance']
                                                        
                                                        //Add the creator of the event as a participant to firebase participant's collection
                                                        pushRealTimeData('Participants', Object.assign({}, {userGamerTag:userGamerTag, userId: user.uid, userProfileImageUrl: userData?.userProfileImageUrl}, {ExhibitionId: docRef.key}))
                                                            .then(() => {
                                                                
                                                            const uuid = docRef.key
            
                                                            const data = {
                                                                dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                                                                groupChatName: formData.ExhibitionName,
                                                                groupImageUrl: '',
                                                                isGroupChat: true,
                                                                chatRoomId: uuid,
                                                                participants: [...selectedParticipants, {
                                                                    objId: user.uid,
                                                                    email: user.email,
                                                                    userName: userGamerTag,
                                                                    userProfileImage: userData.userProfileImageUrl
                                                                }]
                                                            }
    
                                                            var eventId = docRef.key;
                                                            var round = "round_1";
                                                            const matchId = uuidv4().replace(/-/g, '').toUpperCase();
                                                    
                                                            var scoreObj = {
                                                                FirstProfileImageUrl: userData.userProfileImageUrl,
                                                                FirstUserId: user.uid,
                                                                FirstUserName: userGamerTag,
                                                                FirstUserScore: 0,
                                                                MatchId: matchId,
                                                                SecondProfileImageUrl: "",
                                                                SecondUserId: "",
                                                                SecondUserName: "",
                                                                SecondUserScore: 0,
                                                                Winner: ""
                                                            };
                                                                
                                                            db.collection("Schedules").doc(eventId).collection(round).add(scoreObj).then(()=>{
                                                                // return true;
                                                            }).catch((err)=>{
                                                                console.log(err);
                                                                // return false;
                                                            });

                                                            // Create a new chatroom with a uuid
                                                            db.collection("ChatRooms")
                                                            .doc(uuid)
                                                            .set(data)
                                                            .then(() => {
                                                                    // Redirect to the newly created chatRoom  endpoint
                                                                    data.participants.map(each => {

                                                                        updateFirebaseDocument('Exhibitions', eventId, {LinkedGroupChatId: uuid})

                                                                        db.collection('User').doc(each.objId).collection('ChatRoomIds')
                                                                            .add(
                                                                            {
                                                                                id: uuid,
                                                                                isDeleted: false,
                                                                                isDelivered: true,
                                                                            }).then(() => {
            
            
                                                                                dispatch({
                                                                                    type: "REMOVE_SELECTED_PARTICIPANTS",
                                                                                    selectedParticipants
                                                                                })
                
                                                                            })
                                                                            .catch((error) => {
                                                                                console.log(error.message)
                                                                                setLoading(false)
            
                                                                            })
                                                                    })
            
                                                                })
                                                                .catch(function (error) {
                                                                    setLoading(false)
            
                                                                    console.error("Error adding document: ", error);
                                                                });
    
                                                            setLoading(false)
                                                            setSuccess('Exhibition created successfully')
                                                            // history.push('/exhibitions')
                                                            setError('');
                                                            props.onHide()
                                                        }).catch(e=>{
                                                            setSuccess('')
                                                            setLoading(false)
                                                            setError(e.message)
                                                            console.log(e)
                                                        })
                                                    }).catch(e=>{
                                                        setSuccess('')
                                                        setLoading(false)
                                                        setError(e.message)
                                                        console.log(e)
                                                    })
    
    
                                            }).catch(e=>{
                                                setSuccess('')
                                                setLoading(false)
                                                setError(e.message)
                                                console.log(e)
                                            })
                                        }).catch(e => console.log(e))

                                        //Create event


                                    }
                                )
                                .catch(e => {
                                    setSuccess('')
                                    setLoading(false)
                                    setError(e.message)
                                    console.log(e)
                                })


                        } else {
                            setSuccess('')
                            if (formData.ExhibitionEntryFee < 5) {
                                setError('Minimum entry fee is $5')
                            }
                            if (parseInt(userData.userBalance) < formData.ExhibitionEntryFee)
                                setError('Please deposit enough funds to create this event. ')
                            }
                    }
                    catch (e) {
                        setError(e)
                        console.log(e)

                    }
                }
                else {
                    setError('Event name is already taken')
                }


            })
            .catch(e =>{
                console.log(e.message)
                setError(e.message)
            })

    }

    async function handleClickOption() {
        let collectionList = []
        var e = document.getElementById("event-sport");
        var selectedOption = e.options[e.selectedIndex].text;


        await db.collection('Games').where('Game', "==", selectedOption).get()
            .then((snapshot) => {
                const snapshotDoc = snapshot?.docs[0];
                const gameData = snapshotDoc.data();
                setOptions(gameData)

                if("gameCoverArt" in gameData && gameData.gameCoverArt != ""){
                    setGameCoverArt(gameData.gameCoverArt);
                }
                else{
                    setGameCoverArt(logo);
                }
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


        

    }




    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={`true`}
            className={`message-modal`}
        >
            <Modal.Header>


            </Modal.Header>

            <Modal.Body style={{height: "100%", overflowY: "auto", paddingBottom: "10px"}}>
                <>
                    <p ref={scrollView} className={`text-danger`}>{error}</p>
                    <p className={`text-success`}>{success}</p>
                    <form className="form event-form" onSubmit={createEvent}>

                        <img onClick={() => props.onHide()} className='sm-view  pointer back-arrow' src={back_arrow}
                             alt=""/>


                        <p className='form-title text-sm-left text-lg-center'>Add New Exhibition</p>

                        <div className="input-group">

                            <input name="EventName" required type="text" placeholder="Exhibition Name"/>

                            <select id='event-sport' className="pointer" required onChange={handleClickOption}
                                    style={{backgroundImage: `url(${dropdown})`}} name="Eventsport" defaultValue={""}>
                                <option value="">Choose exhibition game</option>
                                {formOptions && formOptions.length > 0 ? formOptions.map(option => {
                                        return (<Option option={option} key={option}/>)
                                    }) :
                                    <>

                                    </>

                                }
                            </select>
                            {
                            (options?.gameType == "Sport") ?
                                <>     
                                <select required id='game-difficulty' className="pointer"
                                    style={{backgroundImage: `url(${dropdown})`}} name="EventDifficulty" defaultValue={""}>
                                    <option value="">Game Difficulty</option>

                                    {(options && options != null) ? <EventOptions eventOption={options.Difficulty}/> : <option>Null</option>}


                                </select>
                                <select required className="pointer" style={{backgroundImage: `url(${dropdown})`}}
                                        name="QuarterLengths" defaultValue={""}>
                                    <option value="">Quarter Length</option>

                                    <>
                                        {quarterLengths.map((each) => {
                                            return (<option key={each} value={each}>{each}</option>)
                                        })}
                                    </>


                                </select>


                                <DatePicker className={`datepicker`} customInput={<CustomDate/>} selected={startDate}
                                            onChange={(date) => changeStartDate(date)} minDate={new Date()} />
                                <DatePicker className={`datepicker`} customInput={<CustomDate/>} selected={endDate}
                                            onChange={(date) => changeEndDate(date)} minDate={startDate}/>


                                <select required className="pointer" style={{backgroundImage: `url(${dropdown})`}}
                                        name="ExhibitionConsole" defaultValue={""}>
                                    <option value="">Exhibition Console</option>

                                    {(options && options != null) ? <EventOptions eventOption={options.Consoles}/> : <option>Null</option>}

                                </select>

                                <select required id='event-style' onChange={maximumParticipants} className="pointer"
                                        style={{backgroundImage: `url(${dropdown})`}} name="EventStyle" defaultValue={""}>
                                    <option value="">Exhibition Style</option>

                                    {(options && options != null) ? <EventOptions eventOption={options.PlayMode}/> : <option>Null</option>}


                                </select>
                                </>

                            :(options?.gameType == "Pokemon VS")&&
                                
                                <>
                                <select required id='battle-type' className="pointer" style={{backgroundImage: `url(${dropdown})`}} name="BatteType" defaultValue={""} onChange={(e)=>{onChangeBattleType(e)}}>
                                    <option value="">Select Battle Type</option>

                                    {(options && options != null) ? <EventOptions eventOption={options.BattleType}/> : <option>Null</option>}


                                </select>
                                <select required className="pointer" style={{backgroundImage: `url(${dropdown})`}}
                                        name="RuleSet" defaultValue={""}>
                                    <option value="">Select Rule Set</option>

                                    {(ruleSetOptions && ruleSetOptions.length > 0) ? <EventOptions eventOption={ruleSetOptions}/> : <option>Null</option>} 


                                </select>


                                <DatePicker className={`datepicker`} customInput={<CustomDate/>} selected={startDate}
                                            onChange={(date) => changeStartDate(date)} minDate={new Date()} />
                                <DatePicker className={`datepicker`} customInput={<CustomDate/>} selected={endDate}
                                            onChange={(date) => changeEndDate(date)} minDate={startDate}/>
                                            
                                <select required className="pointer" style={{backgroundImage: `url(${dropdown})`}}
                                        name="ExhibitionConsole" defaultValue={""}>
                                    <option value="">Exhibition Console</option>

                                    {(options && options != null) ? <EventOptions eventOption={options.Consoles}/> : <option>Null</option>}

                                </select>

                                <select required id='event-style' onChange={maximumParticipants} className="pointer"
                                        style={{backgroundImage: `url(${dropdown})`}} name="EventStyle" defaultValue={""}>
                                    <option value="">Exhibition Style</option>

                                    {(options && options != null) ? <EventOptions eventOption={options.PlayMode}/> : <option>Null</option>}


                                </select>
                                </>
                            }
                            
                            <select required id='event-participants' className="pointer"
                                    style={{backgroundImage: `url(${dropdown})`}} name="EventMaximumParticipants" defaultValue={""}>
                                <option value="">No. of participants</option>

                                {maxParticipants ? <EventOptions eventOption={maxParticipants}/>
                                    : <option>Null</option>}


                            </select>

                            <input required name="EventEntryFee" type="number" onChange={totalPrize}
                                   placeholder="Entry Fee"/>
                            <input name="EventTotalPrizes" value={eventTotalPrizes} disabled type="text"
                                   placeholder="Projected Total Prize"/>
                            <div className='switch-container d-flex center'>
                                <span>Default Prize Tier</span>
                                <Switch className='mr-0 ml-auto'
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                />
                            </div>
                            <div className="range mx-auto">
                                <div className={`d-flex  text-light flex-column align-items-center`}>
                                    <div className={`flex w-100`}><span className={` float-left`}>First Place</span>
                                        <span className={` float-right`} id={`range-1-value`}>40%</span></div>
                                    <input required type="range" name={'range_1'} defaultValue={40}
                                           onChange={adjustSlider} className={`scroll`} min={0} max={100}
                                           id={`range-1`}/></div>
                                <div className={`d-flex  text-light flex-column align-items-center`}>
                                    <div className={`flex w-100`}><span
                                        className={` float-left`}>Second Place</span><span className={` float-right`}
                                                                                           id={`range-2-value`}>25%</span>
                                    </div>
                                    <input type="range" name={'range_2'} defaultValue={25} onChange={adjustSlider}
                                           className={`scroll`} min={0} max={100} id={`range-2`}/></div>
                                <div className={`d-flex  text-light flex-column  align-items-center`}>
                                    <div className={`flex w-100`}><span className={` float-left`}>Third Place</span>
                                        <span className={` float-right`} id={`range-3-value`}>20%</span></div>
                                    <input type="range" name={'range_3'} defaultValue={20} onChange={adjustSlider}
                                           className={`scroll`} min={0} max={100} id={`range-3`}/></div>
                                <div className={`d-flex  text-light flex-column align-items-center`}>
                                    <div className={`flex w-100`}><span
                                        className={` float-left`}>Fourth Place</span><span className={` float-right`}
                                                                                           id={`range-4-value`}>15%</span>
                                    </div>
                                    <input type="range" name={'range_4'} defaultValue={15} onChange={adjustSlider}
                                           className={`scroll`} min={0} max={100} id={`range-4`}/></div>
                            </div>

                        </div>
                        <button disabled={loading} style={{background: loading ? '#ffffff' : ''}} type="submit"><span
                            className={`form-btn `}>Create Exhibition</span></button>

                    </form>
                </>

            </Modal.Body>

        </Modal>
    );
}

function Events() {
    const {queryError, setQueryError, queryEvents, setQueryEvents} = useEvent()
    const [modalShow, setModalShow] = React.useState(false);

    const [searchResultList, setSearchResultList] = useState([])

    const [searchLoading, setSearchLoading] = useState(false)

    const [eventSnap, loading] = useList(realTimeDatabase.ref('Exhibitions').orderByChild('EventTimestamp'))

    const {loader, setLoader} = useLoader()

    const [{user, userData}] = useStateValue()

    // Search functionality
    // Reference https://levelup.gitconnected.com/use-regex-and-javascript-to-improve-search-results-870932050d08
    const checkName = (name, str) => {
        var pattern = str.split("").map((x) => {
            return `(?=.*${x})`
        }).join("");
        var regex = new RegExp(`${pattern}`, "g")
        return name.match(regex);
    }

    async function handleSearch(e) {

        let searchList = [];
        var strSearch = e.target.value.toLowerCase();

        setSearchLoading(true)
        if (!e.target.value) {
            await realTimeDatabase.ref('Exhibitions').orderByChild('EventTimestamp').get()
                .then((snapshot) => {
                    snapshot.forEach(doc => {
                        const data = doc.val()
                        Object.assign(data, {id: doc.key})
                        searchList.push(data)


                    })
                    // setEventsList(searchList)
                    setQueryEvents([...searchList].reverse())
                    setSearchLoading(false)
                })
        } else {
            if (searchResultList?.length > 0) {
                setQueryError('')
                const searchResults = searchResultList?.filter((x) => {
                    if (x.ExhibitionName !== undefined) {
                        var xSub = x.ExhibitionName.substring(0, 4)?.toLowerCase()


                        return x.ExhibitionName?.toLowerCase().includes(strSearch) || checkName(xSub, strSearch)
                    }


                })

                if (searchResults.length > 0 && e.target.value !== "") {
                    setQueryError('')

                    // setEventsList(searchResults)
                    setQueryEvents([...searchResults].reverse());
                    setSearchLoading(false)

                } else {
                    setQueryEvents([])

                    setQueryError('No matches found')
                    setSearchLoading(false)
                }
            } else {
                realTimeDatabase.ref('Exhibitions').orderByChild('EventTimestamp').get()
                    .then((snapshot) => {
                        snapshot.forEach(doc => {
                            const data = doc.val()
                            Object.assign(data, {id: doc.key})

                            searchList.push(data)
                        })

                        setSearchResultList(searchList)

                        setSearchLoading(false)
                    })
            }


        }


        setLoader(false)

    }

    useEffect(() => {


        return () => {

            setQueryEvents([]);
            setQueryError('')

        }
    }, [])


    return (
        <>
            <EventForm
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <div className='container events body'>

                <Header/>
                <div className='lg-view'>
                    <div className=' d-flex align-items-center pt-4'>
                        <h4 className=' text-light'>All Events</h4>

                        <div className="search-container flex-grow-1 ">
                            <div className='search d-flex float-right'>
                                <Search functionHandler={handleSearch}/>
                                <button onClick={() => setModalShow(true)} className="btn  btn-lg-screen">Create Exhibition
                                </button>
                                <button onClick={() => setModalShow(true)} style={{backgroundImage: `url(${plus})`}}
                                        className="  btn-mobile"></button>
                            </div>


                        </div>
                    </div>

                </div>
                <div className='sm-view pl-4 pr-4'>
                    <div className='  d-flex justify-content-center align-items-center pt-4'>
                        <div className='flex-grow-1'>
                            {user ? <h4 className='text-light'>Hi {user?.displayName || userData?.gamerTag},</h4> :
                                <h4 className='text-light'>All Exhibitions</h4>}
                            <p>Let's Discover a new Adventure</p>


                        </div>

                        <div className="search-container flex-grow-1 ">
                            <div className='search d-flex float-right'>
                                <button onClick={() => setModalShow(true)} className="btn  btn-lg-screen">Create Exhibition
                                </button>
                                <button onClick={() => setModalShow(true)} style={{backgroundImage: `url(${plus})`}}
                                        className="  btn-mobile"></button>
                            </div>


                        </div>

                    </div>
                    <Search functionHandler={handleSearch}/>

                </div>
                <Category event={eventSnap}/>
                {queryError && queryEvents.length === 0 && <p className={`text-light text-center`}>{queryError}</p>}
                <div className='grid-container'>
                    <>

                        {queryEvents?.length > 0 && !searchLoading ? queryEvents.map(event => {
                                // if(moment(Date.now()).isSameOrAfter(event.ExhibitionStartDate,'day') && moment(Date.now()).isSameOrBefore(event.ExhibitionEndDate,'day')) {
                                    return (
                                        <>

                                            <Card event={event} id={event.id} key={event.id}/>
                                        </>
                                    )
                                // }
                            })
                            :
                            !loading && !searchLoading && !queryError && eventSnap ? [...eventSnap].reverse().map(event => {
                                //if(moment(moment().valueOf()).isSameOrAfter(event.val().ExhibitionStartDate,'day') && moment(moment().valueOf()).isSameOrBefore(event.val().ExhibitionEndDate,'day')) {
                                      return (<>
                                            <Card event={event.val()} id={event.key} key={event.key}/>
                                        </>)
                                // }
                            })
                            :
                                loading || searchLoading ? <>
                                        <Card event={''} key={1}/>
                                        <Card event={''} key={2}/>
                                        <Card event={''} key={3}/>
                                    </>

                                    :
                                    <></>

                        }
                    </>

                </div>

            </div>
            <Footer/>
            <MobileNavbar/>
        </>
    );
}

export default Events;
