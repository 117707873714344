import React from 'react';

const TransactionCardLoading = ({image}) => {
    return (
        <div  className=' m-3 pointer animated-background menu-item'>
            <div className='icon-wrapper'>
                <div className='center'>
                    <img src={image} alt=""/>

                </div>
            </div>
        </div>

    );
};

export default TransactionCardLoading;