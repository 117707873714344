import React from 'react';
import Header from "../header/header";
import Footer from "../footer/footer";

function HowItWorks() {
    return (
        <div>
            <Header />
            <Footer />
        </div>
    );
}

export default HowItWorks;