import React, {useEffect, useRef, useState} from 'react';
import ChatContent from "./chatContent";
import {useLoader} from "../contexts/loaderContext";
import firebase from "firebase";
import {auth, db, storage} from "../firebase/firebase";
import {Modal} from "react-bootstrap";
import BackButton from "./backButton";
import {useStateValue} from "../contexts/StateProvider"
import {useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth"
import {useCollection, useDocumentOnce} from "react-firebase-hooks/firestore"
import spinner from '../images/spinner.gif'
import UserList from "./userList";
import sendText from '../images/send-text.svg'
import camera from '../images/camera.svg'
import group from "../images/group.png";
import userPhoto from "../images/user.svg";
import {v4 as uuidv4} from "uuid";

function ModalPopup(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={`true`}
            className={`message-modal`}
        >
            <Modal.Header>
                <div className={`text-right text-light mr-0 ml-auto`}>
                    <span onClick={props.onHide}>Dismiss</span>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className={`sub-heading`}>
                    {props.participants?.length > 2 && 'Group'} Participants
                </div>

                {
                    props.participants?.map(each => {
                        return (
                            <UserList user={each}/>
                        )
                    })
                }


            </Modal.Body>

        </Modal>
    );
}

const MessageWindow = ({}) => {
    const [{user, userData}] = useStateValue()
    const [currentUser] = useAuthState(auth)
    const params = useParams()

    const scrollView = useRef();
    const [preview, setPreview] = useState()
    const [file, setFile] = useState('');

    const [previewImageOnSend, setPreviewImageOnSend] = useState([])

    const [modalShow, setModalShow] = React.useState(false);
    const {setLoader} = useLoader();
    const [showSendButton, setShowSendButton] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [input, setInput] = useState('');
    const hiddenFileInput = React.useRef(null);

    // Listens for changes
    const [messagesSnap, loading] = useCollection(db.collection('ChatRooms').doc(params.id).collection('chat').orderBy("dateTime", "asc"));
    const [chatRoomSnap] = useDocumentOnce(db.collection("ChatRooms").where("chatRoomId", "==", params.id));


    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleSendMedia = () => {
        setFile('')
        hiddenFileInput.current.click();

    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        setFile(event.target.files[0])
        const fileUploaded = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(fileUploaded);

        reader.onloadend = function () {
            setPreview(reader.result)
        }
    };
    useEffect(() => {
        // Scrolls to the last text message
        scrollView.current.scrollIntoView({behavior: 'smooth'})

    }, [])


    const chatRoomObject = chatRoomSnap?.docs[0]?.data();
    const receiverObject = chatRoomObject?.participants?.filter(userObj => {
        if (userObj.objId !== user?.uid)
            return userObj
    })

    const textMessages = () => {
        if (messagesSnap) {
            return messagesSnap.docs.map(message => (
                <ChatContent
                    participants={chatRoomObject?.participants.length}
                    key={message.id}
                    sender={message.data().user}
                    preview={previewImageOnSend}
                    loading={loading}
                    fileLoading={fileLoading}
                    message={message.data()}/>
            ))
        }
    }

    const fileType = (fileType) => {
        if (fileType.type.match('image.*')) {
            return `images/${fileType.name}`
        } else if (fileType.type.match('video.*')) {
            return `videos/${fileType.name}`
        } else if (fileType.type.match('audio.*')) {
            return `audios/${fileType.name}`
        } else
            return null
    }


    // Send  message
    const handleSendText = async (e) => {
        e.preventDefault()
        setShowSendButton(false)
        const uuid = uuidv4().replace(/-/g,'').toUpperCase()


        if (file && file.size) {
            setFileLoading(true)
            setPreviewImageOnSend(preview)

            // Push File to the firebase storage
            await storage.ref(chatRoomObject.isGroupChat ? `groupImages/${file.name}` : fileType(file)).put(file)
                .then(snapshot => {
                    console.log('pushed to storage')
                    setFile('')

                    //Fetch the file url
                    snapshot.ref.getDownloadURL().then((url) => {
                        var data = {
                            chatId: uuid,
                            text: e.target.message.value,
                            senderEmail: currentUser.email,
                            senderObjId: currentUser.uid,
                            userProfileImageUrl: userData?.userProfileImageUrl,
                            senderName: userData?.gamerTag,
                            isDelivered: false,
                            isImage: false,
                            isVideo: false,
                            isVoiceNote: false,
                            storageMediaUrl: url,
                            deliveredToParticipants: [currentUser.uid],
                            chatRoomId: params.id,
                            dateTime: firebase.firestore.FieldValue.serverTimestamp()
                        }

                        if (file.type.match('image.*')) {
                            data.storageMediaUrl = url;
                            data.isImage = true
                        } else if (file.type.match('video.*')) {
                            data.storageMediaUrl = url;
                            data.isVideo = true
                        } else if (file.type.match('audio.*')) {

                            data.storageMediaUrl = url;
                            data.isVoiceNote = true
                        } else
                            data.storageMediaUrl = url;

                        db.collection('ChatRooms').doc(params.id).collection('chat').doc(uuid).set(data)
                            .then( () => {
                                //Update chatroom document
                                db.collection('ChatRooms')
                                    .doc(params.id)
                                    .update({dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp()})
                                    .then(() => {

                                        // Increment the receiver notificationCount field in Users Collection
                                        chatRoomObject?.participants.filter(each => {
                                            if (each.objId !== currentUser.uid)
                                                db.collection('User')
                                                    .doc(each.objId)
                                                    .update({notificationCounts: firebase.firestore.FieldValue.increment(1)})

                                        })
                                        setPreviewImageOnSend('')

                                        // Scroll to the new text message
                                        scrollView.current.scrollIntoView({behavior: 'smooth'})

                                    })
                                    .catch(e => {
                                        console.log(e.message)
                                    })
                            })
                            .catch((err) => {
                                console.log(err.message)
                                setFile('')
                                setPreviewImageOnSend('')
                            })


                    })
                    setFile('')
                    setPreview('')

                    setFileLoading(false)


                })
                .catch(error => {
                    setPreviewImageOnSend('')
                    setLoader(false)
                    console.log(error)
                })


        }

        if (e.target.message.value !== "") {
            db.collection('ChatRooms').doc(params.id).collection('chat').doc(uuid).set({
                chatId: uuid,
                text: e.target.message.value,
                senderEmail: currentUser.email,
                senderObjId: currentUser.uid,
                userProfileImageUrl: userData?.userProfileImageUrl,
                senderName: userData?.gamerTag,
                isDelivered: false,
                isImage: false,
                isVideo: false,
                isVoiceNote: false,
                storageMediaUrl: '',
                deliveredToParticipants: [currentUser.uid],
                chatRoomId: params.id,
                dateTime: firebase.firestore.FieldValue.serverTimestamp()
            })
                .then( () => {
                    // Update chatroom dateLastUpdated field
                    db.collection('ChatRooms')
                        .doc(params.id)
                        .update({dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp()})
                        .then(() => {

                            // Increment the receiver notificationCount field in Users Collection
                            chatRoomObject?.participants.filter(each => {
                                if (each.objId !== currentUser.uid)
                                    db.collection('User').doc(each.objId).update({notificationCounts: firebase.firestore.FieldValue.increment(1)})

                            })


                            setInput('')
                            // Scroll to the last text
                            scrollView.current.scrollIntoView({behavior: 'smooth'})
                        })
                        .catch(e => {
                            console.log(e.message)
                        })

                    // setLoading(false)

                })
                .catch(e => {
                    console.log(e.message)
                })
        }
    }

    return (
        <>
            <ModalPopup
                show={modalShow}
                participants={chatRoomObject?.participants}
                onHide={() => setModalShow(false)}
            />
            <div className={`message-window`}>

                <nav className={` nav navbar center  chat-header mb-2`}>
                    <div className={`flex-grow-1 sm-view`}>
                        <BackButton/>

                    </div>
                    <div className=' lg-view mr-5 user-list-thumb-wrapper'>
                        <img
                            src= {chatRoomObject?.isGroupChat ? chatRoomObject?.groupImageUrl  ? chatRoomObject?.groupImageUrl : group : receiverObject ? receiverObject[0]?.userProfileImage ? receiverObject[0]?.userProfileImage : receiverObject[0]?.userProfileImageUrl : userPhoto}
                        alt=""/>
                    </div>
                    <div className='text-sm-center text-lg-left text-md-left flex-grow-1 text-light guest-name'>
                    <span>
                     {chatRoomObject?.isGroupChat ? chatRoomObject.groupChatName : receiverObject && receiverObject[0]?.userName}
                    </span>

                    </div>


                    <div className='justify-content-end flex-grow-1 chat-icon-group mr-0 ml-auto d-flex'>
                        <div className={` lg-view-flex`}>

                        </div>
                        <div className={' icon-wrapper   pointer text-center'}>
                            <div onClick={() => setModalShow(true)} style={{color: '#18ff00'}}
                                 className={'dots-dropdown'}>&#9432;</div>
                        </div>



                    </div>


                </nav>

                <div className={`chat-container`}>
                    <br/>
                    <br/>
                    <br/>


                    {textMessages()}

                    <div ref={scrollView}></div>


                </div>
                <form onSubmit={handleSendText} className='d-flex chat-input align-items-center px-2 overflow-hidden'>
                    <input
                        accept="image/x-png,image/gif,image/jpeg"
                        name={`media`}
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display: 'none'}}

                    />
                    {previewImageOnSend && preview &&
                    <div className={`d-flex h-100 w-25 text-container position-relative`}>
                        <img className={`spinner-loader`} width={24} style={{display: fileLoading ? 'flex' : 'none'}}
                             src={spinner} alt=""/>
                        <img className={`w-100 h-auto`} src={preview} alt=""/>
                    </div>}


                    <input value={input} onChange={(e) => {
                        setInput(e.target.value);
                        setShowSendButton(true)
                    }} name='message'
                           placeholder='Enter Message' type="text" style={{flex:1}}/>
                    <div className={`pt-2 h-100 d-flex align-items-center pb-2 ml-3`}>

                        <button type={`submit`} style={{height:'42px', backgroundImage: `url(${sendText})`}}
                                className={`btn send-text-btn flex-grow-1`}></button>
                        <div className={`ml-2`}>
                            <div className={' icon-wrapper flex-grow-1 p-3 pointer text-center'}
                                 onClick={handleSendMedia}>
                                <img src={camera} alt=""/>
                            </div>
                        </div>

                    </div>
                </form>
                <br/>
                <br/>
                <br/>
            </div>


        </>

    );
}

export default MessageWindow;