import React, { useRef, useState, useEffect } from 'react';
import './signup.css';
import trophy from '../../images/trophy.svg';
import password from '../../images/password.svg';
import mail from '../../images/mail.svg';
import user from '../../images/user.svg';
import { pushData, updateDocument, setRealtimeDoc, setFirestoreDocument } from "../../utility/helper";
import firebase from "firebase";
import { auth, db } from '../../firebase/firebase';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReferralCode from './referralcode';
import Option from "../../components/option";

function Signup(props) {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [platformOptions, setPlatformOptions] = useState({"Playstation": "playstation", "Xbox": "xbox", "Nintendo Switch": "nintendo"});
    const [selectedPlatformOptions, setSelectedPlatformOption] = useState([]);
    const [showReferralCodeModal, setReferralCodeModal] = useState(false);
    let history = useHistory()

    const [inputFields, setInputFields] = useState([{
        platform: '', username: ''
    }]);

    const addInputField = async () => {
        setInputFields([...inputFields, {
            platform: '', username: ''
        }])

    }

    const removeInputFields = async (index) => {
        let rows = [...inputFields];
        rows.splice(index, 1);
        await setInputFields(rows);
    }

    const handleChange = async (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];
        var toAddNewField = true;

        if (list[index] && list[index][name]) {
            toAddNewField = false;
        }

        list[index][name] = value;

        if (list[index]["platform"] == "" || list[index]["username"] == "") {
            toAddNewField = false;
        }

        if (list[(list.length - 1)]["platform"] == "" || list[(list.length - 1)]["username"] == "") {
            toAddNewField = false;
        }

        if (list[index]["platform"] == "" && list[index]["username"] == "") {
            if ((index + 1) < inputFields.length) {
                removeInputFields(index);
            }
        }
        else {
            setInputFields(list);
            if (toAddNewField) {
                addInputField()
            }
        }
        var selectedPlatformOptiosArr = [];
        inputFields.map((inputField)=>{
            if(inputField.platform != ""){
                selectedPlatformOptiosArr.push(inputField.platform);
            }
        })
        setSelectedPlatformOption(selectedPlatformOptiosArr);
    }

    async function signUp(e) {
        if (inputFields[0].username.trim() == "") {
            setError('Please enter Game Tag')
            return ""
        } else if (emailRef.current.value.trim() == "") {
            setError('Please enter Email')
            return ""
        } else if (passwordRef.current.value.trim() == "") {
            setError('Please enter Password')
            return ""
        }

        setError('')
        setLoading(true)

        const username = inputFields[0].username;

        const users = await db.collection('User').get();

        var username_exists_satus = false;
        var email_exists_status = false;
        users.forEach(user => {
            const usrData = user.data();
            if (usrData.userName && usrData.userName.toLowerCase() == username.toLowerCase()) {
                username_exists_satus = true;
            }
            else if (usrData.gamerTag && usrData.gamerTag.toLowerCase() == username.toLowerCase()) {
                username_exists_satus = true;
            }

            if (usrData.email?.toLowerCase() == emailRef.current.value.toLowerCase()) {
                email_exists_status = true;
            }
        })

        var gamerTagData = {};
        var gamerTagValidateErr = false;
        inputFields.map((gamerTags)=>{
            if((gamerTags.platform.trim() != "" && gamerTags.username.trim() == "") || (gamerTags.platform.trim() == "" && gamerTags.username.trim() != "")){
                gamerTagValidateErr = true;
            }

            if(gamerTags.platform.trim() != "" && gamerTags.username.trim() != ""){
                gamerTagData[gamerTags.platform+'GamerTag'] = gamerTags.username;
            }
        })

        if(gamerTagValidateErr){
            setLoading(false);
            setError('GamerTag can not be empty.');

            return "";
        }

        if (username_exists_satus) {
            setLoading(false);
            setError('Username already exists. Please use another one.');

            return "";
        }

        if (email_exists_status) {
            setLoading(false);
            setError('Email already exists. Please use another one.');

            return "";
        }
        
        await props.setReferralCodeData({ email: emailRef.current.value, password: passwordRef.current.value, username: username, gamerTagData: gamerTagData });
        await props.onSignUp('referralCode');


    }

    return (
        <>

            <form className="form">
                <div className={`header-content `}>
                    <img className="form-image" src={trophy} alt="" />
                    <p className="form-title pt-4">ClutchTime</p>
                    <p className='form-text f-18'>Please register your details to continue
                        with ClutchTime</p>
                </div>
                <p className="text-danger">{error}</p>

                <div className="input-group">
                    <input ref={emailRef} name="email" style={{ backgroundImage: `url(${mail})` }} type="Email"
                        placeholder="Email" />
                    <input ref={passwordRef} name="password" style={{ backgroundImage: `url(${password})` }}
                        type="Password" placeholder="Password" />

                </div>
                {
                    inputFields.map((data, index) => {
                        const { platform, username } = data;
                        return (
                            <div className="input-group" key={index}>
                                <select className="pointer" required value={platform} onChange={(evnt) => handleChange(index, evnt)}
                                    style={{ width: "23%", marginRight: "5px", textIndent: "0px" }} name="platform">
                                    <option value="">Platform</option>
                                    {Object.entries(platformOptions).length > 0 && Object.entries(platformOptions).map(([key, option]) => {
                                        return(<option key={option} value={option}
                                            disabled={(selectedPlatformOptions.indexOf(option) > -1)}>{key}</option>)
                                        
                                    })
                                    }
                                </select>
                                <input onChange={(evnt) => handleChange(index, evnt)} value={username} name="username"
                                    style={{ backgroundImage: `url(${user})`, width: "53%", marginLeft: "5px" }} type="text"
                                    placeholder="Gamer Tag" />
                            </div>
                        )
                    })
                }
                <button disabled={loading} style={{ background: loading ? '#ffffff' : '' }} type="button" onClick={signUp}>
                    <span className='form-btn'>Sign Up</span></button>
            </form>

        </>
    );
}

export default Signup;


