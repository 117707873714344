import React, { useRef, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import './withdraw.css';
import mobile from '../../images/mobile.png';
import mail from '../../images/mail.svg';
import { useHistory } from 'react-router-dom'
import { useStateValue } from '../../contexts/StateProvider';
import { pushRealTimeData, updateFirebaseDocument, updateFirestoreDocument, updateDocument } from "../../utility/helper";
import logo from '../../images/logo.png';
import axios from 'axios';
import { css } from "@emotion/react";


export default function Withdraw(props) {

    const [{ user, userData }, dispatch] = useStateValue()
    const emailRef = useRef()
    const phoneNumberRef = useRef()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [success, setSuccess] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const override = css`
      display: block;
      margin: 0 7%;
      margin-left: auto;
      margin-right: auto;`;
    let [color, setColor] = useState("#18ff00");

    const withdraw = (e) => {
        e.preventDefault()
        if (email == "") {
            setError('Please enter email address.')
            setLoading(false)
        } else if (phoneNumber == "") {
            setError('Please enter phone number.')
            setLoading(false)
        } else if (error == "") {
            setLoading(true)
            let payeeArr = []

            payeeArr.push({ "amount": { "currency_code": "USD", "value": props.amount }, "payee": { "email_address": email, "phone_number": phoneNumber } })

            var data = new FormData();
            data.append('device', 'Web');
            data.append('purchase_units', JSON.stringify(payeeArr));
            data.append('login_user_id', userData?.objectId);

            axios({
                method: 'post',
                url: 'http://www.swicb.com/wallettransfer/lara/public/index.php/api/create-payout',
                headers: { 'content-type': 'application/json' },
                data: data
            })
                .then((result) => {
                    // if (result.status == 200) {
                        var resultData = result.data;
                        if (resultData.status_code == 200) {

                            var responseData = resultData.data;
                            var batchHeader = responseData.batch_header;

                            if (batchHeader.batch_status == "SUCCESS") {
                                var amount = parseFloat(batchHeader.amount.value);
                                var userBalance = parseFloat(userData.userBalance) - amount;

                                updateFirestoreDocument('User', user.uid, { userBalance: userBalance })
                                    .then(() => {

                                        //Update userData balance in firebase userData document
                                        updateFirebaseDocument('Users', user.uid, { userBalance: userBalance })
                                            .catch(e => {
                                                console.log(e);
                                                updateFirestoreDocument('User', user.uid, { userBalance: parseInt(userData.userBalance) })
                                            })

                                        userData.userBalance = userBalance;
                                        dispatch({
                                            type: "SET_USER_DATA",
                                            userData
                                        })

                                        setEmail('')
                                        setPhoneNumber('')
                                        setLoading(false)
                                        setSuccess('Withdrawal successful');
                                        setTimeout(() => {
                                            props.onHide()
                                        }, 3000);

                                    })
                                    .catch(e => {
                                        console.log(e)
                                        setError('Withdrawal unsuccessful')
                                    }
                                    )

                            } else {
                                setError('Withdrawal unsuccessful')
                            }
                        }
                    // } else {
                    //     setError('Withdrawal unsuccessful')
                    // }
                    // setSuccess('Withdrawal successful')

                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const handleEmailChange = (event) => {

        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let val = event.target.value;

        if (regex.test(val)) {
            setError("")
        } else {
            setError("Please enter valid email address.")
        }
        setEmail(val)

    }

    const handlePhoneNumberChange = (event) => {

        let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        let val = event.target.value;

        if (regex.test(val)) {
            setError("")    
        } else {
            setError("Please enter valid phone number.")
        }
        setPhoneNumber(val)

    }

    return (
        <>
            <form className="form" onSubmit={withdraw}>
                <div className={`header-content `}>
                    <img className="form-image" src={logo} alt="" />
                    <p className="form-title pt-4">{`Amount : ${props.amount} USD`}</p>
                    <p className='form-text f-18'>Please enter your PayPal account and phone number to withdraw.</p>
                </div>
                <p className="text-danger">{error}</p>
                <p className="text-success">{success}</p>

                <div className="input-group">
                    <input ref={emailRef} name="email" style={{ backgroundImage: `url(${mail})` }} type="Email"
                        placeholder="Email" value={email} onChange={handleEmailChange} />
                    <input ref={phoneNumberRef} onChange={handlePhoneNumberChange} name="phoneNumber"
                        style={{ backgroundImage: `url(${mobile})` }} value={phoneNumber} type="text"
                        placeholder="Phone Number" />
                </div>

                <button style={{ background: loading ? '#ffffff' : '' }} type="submit">{(loading) ? <PuffLoader css={override} loading={loading} size={24} color={color} /> : <span className='form-btn'>Withdraw</span>}</button>
            </form>
        </>
    );
}
