import React from 'react';
import Footer from '../footer/footer';
import Header from '../header/header';
import './HowItWork.css';

export default function HowItWork() {
    return (
        <>
            <Header />
            <div className="container pt-3 pb-5">
                <h3 className='text-center font-weight-bold pb-5' style={{ color: '#18ff00' }}>How does it work</h3>
                <p className='text-white text-left font-weight-bold' style={{ fontSize: '25px', display: "inline" }}>How does it work</p>
                <p className='text-white font-weight-normal text-justify mt-2 mb-4' style={{ verticalAlign: 'center', fontSize:'14.4px' }}>
                    You can create an event or join an event, read more below about the playing styles, consoles, and games supported.
                </p>
                <div className="collapse-accordion">
                    <ul>
                        <li>
                            <input type="checkbox" id="list-item-1" name="accrodion" />
                            <label htmlFor="list-item-1" className="first">
                                <span className="expandText text-dark text-bolder">Is this gambling ?</span>
                                <span className="collpaseText text-dark text-bolder">Is this gambling ?</span>
                            </label>
                            <ul>
                                <p>No this is not gambling, this is a player's skill matched up against another player's skill. By law gambling is defined as a game of chance, with an entry fee, and with a prize. Luckily you are not taking chances here and are putting your skills to the test in the games you love. This is Egaming.</p>
                            </ul>
                        </li>
                        <li>
                            <input type="checkbox" id="list-item-2" name="accrodion" />
                            <label htmlFor="list-item-2">
                                <span className="expandText text-dark text-bolder">How do I know my money is secure ?</span>
                                <span className="collpaseText text-dark text-bolder">How do I know my money is secure ?</span>
                            </label>
                            <ul>
                                <p>We utilize PayPal for our transactions, the funds for each event is placed into an escrow account and once the winner is verified the funds are released. We do not process any financial information, we let the professionals do that.</p>
                            </ul>
                        </li>
                        <li>
                            <input type="checkbox" id="list-item-3" name="accrodion" />
                            <label htmlFor="list-item-3" className="last">
                                <span className="expandText text-dark text-bolder">Why is a Twitch channel or Youtube Channel required to stream games?</span>
                                <span className="collpaseText text-dark text-bolder">Why is a Twitch channel or Youtube Channel required to stream games?</span>
                            </label>
                            <ul>
                                <p>Just like any sport we would like to have footage of the events. Also this allows you to see your own game play and improve your skills.</p>
                            </ul>
                        </li>
						<li>
                            <input type="checkbox" id="list-item-4" name="accrodion" />
                            <label htmlFor="list-item-4" className="last">
                                <span className="expandText text-dark text-bolder">How do I know which console is being used for an event?</span>
                                <span className="collpaseText text-dark text-bolder">How do I know which console is being used for an event ?</span>
                            </label>
                            <ul>
                                <p>Once you log into our app, on the home screen, on an event card look at the bottom right corner. If you click on an event to view the details, look towards the top left corner.</p>
                            </ul>
                        </li>
                        <li>
                            <input type="checkbox" id="list-item-5" name="accrodion" />
                            <label htmlFor="list-item-5" className="last">
                                <span className="expandText text-dark text-bolder">How do I know the entry fee for an event?</span>
                                <span className="collpaseText text-dark text-bolder">How do I know the entry fee for an event?</span>
                            </label>
                            <ul>
                                <p>Once you log into our app, on the home screen, on an event card look at the top right corner. If you click on an event to view the details, right below the image to the left in green will be the entry fee.</p>
                            </ul>
                        </li>
		                 <li>
                            <input type="checkbox" id="list-item-6" name="accrodion" />
                            <label htmlFor="list-item-6" className="last">
                                <span className="expandText text-dark text-bolder">How do I know when we are supposed to play?</span>
                                <span className="collpaseText text-dark text-bolder">How do I know when we are supposed to play?</span>
                            </label>
                            <ul>
                                <p>Once you register and the event is filled with the maximum number of participants, the commissioner will notify everyone of the play period. Each round of event is to be finished within 2 days. You may send an event to your opponent for each respective round by going to the profile screen -> create schedule -> click on the event you would like to send the invite for -> click on your match up.</p>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
}