import React from 'react';


function Option({option}) {
    return (
        <>
            <option  className='sport-opt' value={option.Sport}>{option.Game}</option>
        </>
    );
}

export default Option;