import React, {useState} from 'react';
import arrow from '../../images/arrow.svg'
import './header.css'
import {auth} from "../../firebase/firebase";
import {Dropdown} from "react-bootstrap";
import {Link, useHistory} from 'react-router-dom'
import {useStateValue} from '../../contexts/StateProvider';


function CurrentUserDropdown() {
    const [{user,userData}, dispatch] = useStateValue();

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    async function handleLogOut(e) {
        e.preventDefault()
        try {

            await auth.signOut()
            dispatch({
                type:"SET_USER",
                user:{}
            })
            dispatch({
                type:"SET_USER_DATA",
                userData:{}
            })
            history.push('/')


        } catch (err) {
            setError(err.message)
            setLoading(false)

        }
    }

    return (
        <>
        <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
                <div className=" d-inline-flex pointer center user-thumb-container">
                    <div className='user-img-wrapper'>

                                <img src={userData?.userProfileImageUrl || user?.photoURL} alt=""/>



                    </div>
                    <div>
                        <img src={arrow} alt=""/>
                    </div>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item  ><Link to={`/user/${user?.uid}`}>Profile</Link></Dropdown.Item>
                <Dropdown.Item onClick={handleLogOut} >Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </>
    );
}

export default CurrentUserDropdown;