import React from 'react';
import Header from "../header/header";
import Footer from '../footer/footer';

function About() {
    return (
        <div>
            <Header/>
            <div className="container pt-3 pb-5">
                <h3 className='text-center font-weight-bold pb-5' style={{ color: '#18ff00' }}>About Us</h3>
                <p className='text-white font-weight-normal text-justify mt-2 mb-4' style={{ verticalAlign: 'center', fontSize: '14.4px' }}>
                    ClutchTime, Inc. is a company comprised of passionate individuals looking to disrupt the E-Sports and gaming industry. We look to establish regulation and order in eGaming leagues. We are also working to provide our users with the convenience of participating in any E-Sports league from the comfort of their home. We aim to assist our users to become professional eGamers and grow their brand, while also improving their skills and becoming the best they can be. We are constantly researching and looking for ways to improve our products and services. Please do not hesitate to reach out to us.
                    <br/><br/>
                  	Long story short we are here to make sure you play a fair game and win money. 
                </p>
            </div>
            <Footer/>
        </div>
    );
}

export default About;