import React, {useEffect, useState} from 'react';
import Search from "../search/search";
import MessageCard from "../../components/messageCard";
import MobileNavbar from "../../components/mobileNavbar";
import Header from "../header/header";
import Footer from "../footer/footer";
import MessageWindow from "../../components/messageWindow";
import '../message/message.css'
import {updateFirestoreDocument} from "../../utility/helper";
import UserList from "../../components/userList";
import {auth, db, storage} from "../../firebase/firebase";
import {useChat} from "../../contexts/messageContext";
import {useHistory, useParams} from "react-router-dom";
import {useStateValue} from '../../contexts/StateProvider';
import {useAuthState} from "react-firebase-hooks/auth"
import plus from "../../images/plus.svg";
import {Modal} from "react-bootstrap";
import Icon from "../../components/icon";
import group from "../../images/group.png";
import firebase from "firebase";
import UserContact from "../../components/UserContact";
import MessageCardLoading from "../../components/messageCardLoading";
import { v4 as uuidv4 } from 'uuid';

function ModalPopup(props) {
    const [{user, selectedParticipants, userData}, dispatch] = useStateValue()
    const [contacts, setContacts] = useState([])
    const [participants, setParticipants] = useState([])
    const [queryParticipants, setQueryParticipants] = useState([])
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(true);
    const hiddenFileInput = React.useRef(null);
    const [showSelectUsers, setShowSelectUsers] = useState(false)
    const [queryError, setQueryError] = useState('')
    const [showAddGroupInfo, setAddGroupInfo] = useState(false)
    const [totalContacts, setTotalContacts] = useState(0)


    const history = useHistory()
    const contactList = []

    // Search functionality
    // Reference https://levelup.gitconnected.com/use-regex-and-javascript-to-improve-search-results-870932050d08
    const checkName = (name, str) => {
        var pattern = str.split("").map((x) => {
            return `(?=.*${x})`
        }).join("");
        var regex = new RegExp(`${pattern}`, "g")
        return name.match(regex);
    }
    const handleSearchUser =  (e) => {

        var strSearch = e.target.value.toLowerCase();
        var strlength = strSearch.length;



        if(!e.target.value){
            setQueryError('')
            setQueryParticipants([])

        }

        else{
            if(participants.length > 0){
                // Search for participant name
                try{
                    const searchResults = participants.filter((x) => {

                        var xSub = x.userName.substring(0, 4).toLowerCase()


                        return x.userName.toLowerCase().includes(strSearch)  && x.objId !== user?.uid || checkName(xSub, strSearch) && x.objId !== user?.uid
                    })
                    if (searchResults.length > 0){
                        setQueryParticipants(searchResults)
                    }
                    else  {


                            setQueryParticipants([])
                            setQueryError('No matches found')



                    }

                }
                catch (e) {
                    console.log('Error occurred')
                }


            }



        }








    }

    const handleCreateGroupChat = async (e) => {
        setLoading(true)
        e.preventDefault()


        if (selectedParticipants.length > 0) {
            const uuid = uuidv4().replace(/-/g,'').toUpperCase()

            const data = {
                dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                groupChatName: e.target.groupChatName.value,
                groupImageUrl: '',
                isGroupChat: true,
                chatRoomId: uuid,
                participants: [...selectedParticipants, {
                    objId: user.uid,
                    email: user.email,
                    userName: userData?.gamerTag,
                    userProfileImage: userData?.userProfileImageUrl
                }]
            }


            // Create a new chatroom with a uuid

            db.collection("ChatRooms")
                .doc(uuid)
                .set(data)
                .then(() => {

                    // Redirect to the newly created chatRoom  endpoint
                    data.participants.map(each => {
                        db.collection('User').doc(each.objId).collection('ChatRoomIds')
                            .add(
                            {
                                id: uuid,
                                isDeleted: false,
                                isDelivered: true,
                            }).then(() => {
                            try {
                                {
                                    // Check if there is a file
                                    file && file.size
                                        ?

                                        // Push File to the firebase storage
                                        storage.ref(`groupImages`).put(file)
                                            .then(snapshot => {

                                                //Fetch the file storage url
                                                snapshot.ref.getDownloadURL().then((url) => {

                                                    // Update Chatroom groupImageUrl field
                                                    var updatedInfo = {groupImageUrl: url}
                                                    updateFirestoreDocument('ChatRooms', uuid, updatedInfo)


                                                })
                                                setFile('')


                                            })
                                            .catch(error => {
                                                setLoading(false)
                                                console.log(error.message)
                                            })


                                        :
                                        console.log('No image')


                                }

                            } catch (e) {
                                setLoading(false)
                                console.log(e.message)
                                console.log('error')

                            }

                            dispatch({
                                type: "REMOVE_SELECTED_PARTICIPANTS",
                                selectedParticipants
                            })
                            setLoading(false)
                            props.onHide()
                            history.push(`/messages/${uuid}`)

                        })
                            .catch((error) => {
                                console.log(error.message)
                                setLoading(false)

                            })
                    })

                })
                .catch(function (error) {
                    setLoading(false)

                    console.error("Error adding document: ", error);
                });
        }


        setLoading(false)


    }
    useEffect(() => {

        if (userData?.followedUsersIds) {
            userData.followedUsersIds.map((each) => {
                db.collection('User').doc(each).get()
                    // getFirestoreDocument('User','objectId',each)
                    .then(doc => {
                        contactList.push({...doc.data(), id: doc.id})
                        setLoading(false)
                        setParticipants(contactList)
                        setTotalContacts(contactList.length)
                        var contactsGrouped = contactList.reduce(function (contactList, userObj) {

                            var contactLetterGroup = contactList.filter(function (list) {
                                if(list.letter === userObj.gamerTag[0].toUpperCase()){
                                    return list
                                }
                            });
                            if (contactLetterGroup.length > 0) {
                                contactLetterGroup[0].user.push(userObj);
                                contactLetterGroup[0].user.sort(function (a, b) {
                                    return a.gamerTag > b.gamerTag ? 1 : -1;
                                })
                            } else {
                                contactList.push({
                                    letter: userObj.gamerTag[0].toUpperCase(),
                                    user: [userObj]
                                });
                            }
                            return contactList;
                        }, [])
                            .sort(function (a, b) {
                                return b.letter > a.letter ? 1 : -1;
                            })
                        setContacts(contactsGrouped.reverse())

                    })
                    .catch(e => {
                        console.log(e.message)
                        setLoading(false)
                    })
            })
        }


    }, [userData])


    // Programmatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = () => {
        setFile('')
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        setFile(event.target.files[0])
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={`true`}
            className={`message-modal`}
        >
            <Modal.Header>
                {/*<div className={`text-right text-light mr-0 ml-auto`}>*/}
                {/*    <span onClick={ props.onHide}>Dismiss</span>*/}
                {/*</div>*/}
            </Modal.Header>

            <Modal.Body>
                {!showSelectUsers && !showAddGroupInfo && <div id={`create-group `} className={`create-group`}>
                    <div className={`d-flex text-light`}>

                        <span className={`flex-grow-1`}> </span>
                        <div className={`flex-grow-1`}>
                            <h5>New Chat</h5>
                        </div>
                        <span onClick={props.onHide} className={`flex-grow-1`}>Cancel</span>
                    </div>

                    <Search functionHandler={handleSearchUser} props={'#2B3038'}/>
                    <div onClick={() => {
                        setShowSelectUsers(true)
                    }} className={`d-flex align-items-center mb-4 text-light`}>
                        <Icon props={{backgroundColor: "#2B3038", image: group}}/>
                        <span className={`ml-3`}>Create Group</span>
                    </div>

                    {!loading && contacts && queryParticipants.length  === 0 && !queryError ? contacts?.map(obj => {
                        return (<>
                                <div className={`sub-heading`}>
                                    <span className={`ml-3 `}>{obj.letter}</span>
                                </div>
                                {obj.user.map(each => {
                                    return (
                                        <UserContact
                                            key={each.objectId}
                                            id={each.userId || each.objectId}
                                            gamerTag={each.gamerTag}
                                            userProfileImageUrl={each.userProfileImageUrl}
                                            email={each.email}
                                        />)
                                })}
                            </>


                        )
                    }) : <></>}
                    {!loading && queryParticipants ? queryParticipants.map(each => {
                        return (
                                        <UserContact
                                            key={each.objectId}
                                            id={each.userId || each.objectId}
                                            gamerTag={each.gamerTag}
                                            userProfileImageUrl={each.userProfileImageUrl}
                                            email={each.email}
                                        />


                        )
                    }) : <></>}
                    { queryError && queryParticipants.length === 0 && <p className={`text-light m-4 text-center`}>{queryError}</p>}



                </div>}
                {showSelectUsers && !showAddGroupInfo &&
                <div style={{height: showSelectUsers ? '100%' : '0%'}} className={`d-flex create-group flex-column`}>
                    <div className={`d-flex text-light mb-4`}>
                    <span onClick={() => {
                        setShowSelectUsers(false);
                        setAddGroupInfo(false)
                    }} className={`flex-grow-1`}>Cancel</span>
                        <div className={`flex-grow-1`}>
                            <h5>Add Participants</h5>
                            <small>{selectedParticipants.length}/{totalContacts}</small>
                        </div>
                        <span onClick={() => {
                            setShowSelectUsers(false);
                            setAddGroupInfo(true)
                        }} className={`flex-grow-1`}>Next</span>
                    </div>

                    {!loading && contacts ? contacts.map(obj => {
                        return (<>
                                <div className={`sub-heading`}>
                                    <span className={`ml-3 `}>{obj.letter}</span>
                                </div>
                                {obj.user.map((each) => {
                                    return (
                                        <UserList multiSelect={true} canBeSelected={true} user={each}
                                                  key={each.objectId}/>


                                    )
                                })}
                            </>


                        )
                    }) : <></>}
                </div>}
                {!showSelectUsers && showAddGroupInfo &&
                <form onSubmit={handleCreateGroupChat} className={`d-flex flex-column`}>
                    <div className={`d-flex text-light mb-4`}>

                        <span onClick={() => {
                            setShowSelectUsers(true);
                            setAddGroupInfo(false)
                        }} className={`flex-grow-1`}>Cancel</span>
                        <div className={`flex-grow-1`}>
                            <h5>New Group</h5>
                        </div>
                        <button type={`submit`} disabled={loading}
                                className={`flex-grow-1 text-btn`}>{!loading ? 'Create' : 'Creating...'}</button>
                    </div>
                    <div className="input-group">
                        <div className={`ml-5 mb-3 d-flex text-light align-items-center`}>
                            <svg onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 450 450">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_15" data-name="Rectangle 15" width="150" height="150"
                                              transform="translate(230 500)" fill="#18ff00"/>
                                    </clipPath>
                                </defs>
                                <g id="camera" transform="translate(-80 -350)">
                                    <g id="Rectangle_14" data-name="Rectangle 14" transform="translate(80 350)"
                                       fill="#13161a" stroke="#18ff00" stroke-width="5" stroke-dasharray="20">
                                        <rect width="450" height="450" rx="225" stroke="none"/>
                                        <rect x="2.5" y="2.5" width="445" height="445" rx="222.5" fill="none"/>
                                    </g>
                                    <g id="Mask_Group_9" data-name="Mask Group 9" clip-path="url(#clip-path)">
                                        <g id="camera-line_11_" data-name="camera-line (11)"
                                           transform="translate(230 500)">
                                            <path id="Path_16" data-name="Path 16" d="M0,0H150V150H0Z" fill="none"/>
                                            <path id="Path_17" data-name="Path 17"
                                                  d="M61.425,31.25l-12.5,12.5H25v75H125v-75H101.075l-12.5-12.5ZM56.25,18.75h37.5l12.5,12.5h25a6.25,6.25,0,0,1,6.25,6.25V125a6.25,6.25,0,0,1-6.25,6.25H18.75A6.25,6.25,0,0,1,12.5,125V37.5a6.25,6.25,0,0,1,6.25-6.25h25ZM75,112.5a34.375,34.375,0,1,1,34.375-34.375A34.375,34.375,0,0,1,75,112.5ZM75,100A21.875,21.875,0,1,0,53.125,78.125,21.875,21.875,0,0,0,75,100Z"
                                                  fill="#18ff00"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span className={`ml-5`}>{file && file.name && 'Image selected successfully'}</span>

                        </div>

                        <input
                            name={`photo`}
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{display: 'none'}}

                        />

                    </div>

                    <input style={{backgroundImage: `url(${group})`}} placeholder={`Group name`} name={`groupChatName`}
                           type="text"/>


                </form>}

            </Modal.Body>

        </Modal>
    );
}

function Message() {
    const [currentUser] = useAuthState(auth)
    const [queryError,setQueryError] =useState()
    const [queryChats,setQueryChats] =useState([])
    const [{user}] = useStateValue()
    // Toggle Message Window on mobile or small screen
    // Shows Message window component if true
    const {show, setShow} = useChat();
    const [chats, setChats] = useState([])
    const [searchedChats, setSearchedChats] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [modalShow, setModalShow] = React.useState(false);

    const [searchLoading, setSearchLoading] = useState(false)
    let params = useParams();
    const messageId = params.id;


    useEffect(() => {
        {
            params.id ? setShow(true)
                :
                setShow(false)
        }

    }, [])

    useEffect(() => {
        if (user.uid) {
            db.collection("User")
                .doc(user.uid)
                .collection("ChatRoomIds")
                .onSnapshot((snapshot) => {
                    snapshot.docs.map((each) => {
                        db.collection("ChatRooms")
                            .where("chatRoomId", "==", each.data().id)
                            .orderBy("dateLastUpdated", "desc")
                            .onSnapshot((snapshot) => {
                                const newChats = snapshot.docs.map((doc) => doc.data());


                                setChats((chats) =>
                                    chats.filter(chat =>
                                        !newChats.some(newChat =>
                                            newChat.chatRoomId === chat.chatRoomId
                                        )
                                    ).concat(newChats)?.sort((c1, c2) => {
                                        if (c1?.dateLastUpdated?.seconds > c2?.dateLastUpdated?.seconds) {
                                            return -1;
                                        } else {
                                            return 1;
                                        }
                                    }));

                                setLoading(false)
                            });
                    });
                })


        }



    }, []);
    useEffect(() => {
        setSearchedChats([])
        {
            chats &&
            localStorage.setItem('storedChats', JSON.stringify(chats))
            setLoading(false)
        }
    }, [chats])

    // Search functionality
    // Reference https://levelup.gitconnected.com/use-regex-and-javascript-to-improve-search-results-870932050d08
    const checkName = (name, str) => {
        var pattern = str.split("").map((x) => {
            return `(?=.*${x})`
        }).join("");
        var regex = new RegExp(`${pattern}`, "g")
        return name.match(regex);
    }
    const handleSearchChat =  (e) => {
        const tempChats = JSON.parse(localStorage.getItem("storedChats"));

        var strSearch = e.target.value.toLowerCase();

        setSearchLoading(true)
        if(!e.target.value){
            setQueryChats(JSON.parse(localStorage.getItem("storedChats") || "[]"))

        }

        else{
            if(tempChats.length > 0){
                // Search for groupchat name
                try{
                    const searchResults = tempChats.filter((x) => {

                        var xSub = x.groupChatName.substring(0, 4).toLowerCase()


                        return x.groupChatName.toLowerCase().includes(strSearch) || checkName(xSub, strSearch)
                    })
                    if (searchResults.length > 0){
                        setQueryChats(searchResults)
                    }
            else  {

                        // Search for participant's name
                                 const filteredPrivateChat = tempChats.filter(each => {
                                    var participantObj = each.participants.filter((x) => {

                                        var xSub = x.userName.substring(0, 3).toLowerCase()


                                        return x.gamerTag.toLowerCase().includes(strSearch) && x.objId !== user.uid || checkName(xSub, strSearch) && x.objId !== user.uid
                                    })
                                     return participantObj.length > 0 && !each.isGroupChat

                                })
                        if (filteredPrivateChat.length > 0){
                            setQueryChats(filteredPrivateChat)
                        }
                        else {
                            setQueryChats([])
                            setQueryError('No matches found')
                        }


                    }

                }
                catch (e) {
                    console.log('Error occurred')
                }


            }



        }








    }

    return (
        <>
            <ModalPopup
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <Header/>
            <div className='message-container container container-lg container-xl container-md'>


                <div className='message '>
                    <div className='row'>
                        <div className='col-md-4 col-lg-4 col-sm-12 lg-view'>
                            <div className=' pt-3 user-list-section'>
                                <div className='lg-view'>
                                    <h5 className='text-light ml-5 mb-5`'>Messages</h5>
                                    <div className={`d-flex align-items-center`}>
                                        <Search functionHandler={handleSearchChat} props={'#13161A'}/>
                                        <button onClick={() => {
                                            setModalShow(true)
                                        }} style={{backgroundImage: `url(${plus})`}}
                                                className="btn lg-view add-btn"></button>
                                        <button onClick={() => {
                                            setModalShow(true)
                                        }} style={{backgroundImage: `url(${plus})`}} className="  btn-mobile"></button>


                                    </div>

                                </div>
                                <div className='user-list'>
                                    {
                                        !loading && queryChats && queryChats.length > 0 ?
                                            queryChats.map(chat => {

                                                return (<>

                                                        {currentUser && <MessageCard key={chat.chatRoomId}
                                                                                     currentUser={currentUser}
                                                                                     id={chat.chatRoomId}
                                                                                     chats={chat}/>}


                                                    </>
                                                )
                                            })
                                            :
                                            !loading && chats  && queryChats.length === 0 && !queryError  ? chats.map(chat => {

                                                    return (<>
                                                            {currentUser && <MessageCard key={chat.chatRoomId}
                                                                                         currentUser={currentUser}
                                                                                         id={chat.chatRoomId}
                                                                                         chats={chat}/>}


                                                        </>
                                                    )
                                                })
                                                :
                                                error ? <span
                                                        className={`text-light mx-auto text-center`}>{!loading && error && error}</span>
                                                    :
                                                    queryError && queryChats.length === 0 ? <p className={`text-light m-4 text-center`}>{queryError}</p>

                                        :
                                                    loading  ?
                                                        <>
                                                            <MessageCardLoading />
                                                            <MessageCardLoading />
                                                            <MessageCardLoading />

                                                        </>
                                                        : <>
                                                            <MessageCardLoading />
                                                            <MessageCardLoading />
                                                            <MessageCardLoading />

                                                        </>


                                    }

                                </div>


                            </div>


                        </div>
                        <>
                            <div className='col-md-8 p-0 col-lg-8 col-sm-12'>
                                {
                                    messageId && <MessageWindow/>

                                }

                            </div>

                        </>
                        <div className={` ${show ? 'd-none' : ' w-100 sm-view'}`}>
                            <div className={`  w-100 pl-3 pr-3 `}>
                                <div className=' w-100  d-flex justify-content-center  pt-4'>
                                    <div className='flex-grow-1'>
                                        <h4 className='text-light'>Messages</h4>
                                        <p>Talk with your friends</p>


                                    </div>

                                    <div className="search-container flex-grow-1 ">
                                        <div className='search  d-flex float-right'>
                                            <button onClick={() => {
                                                setModalShow(true)
                                            }} style={{backgroundImage: `url(${plus})`}}
                                                    className="btn lg-view add-btn"></button>
                                            <button onClick={() => {
                                                setModalShow(true)
                                            }} style={{backgroundImage: `url(${plus})`}}
                                                    className="  btn-mobile"></button>
                                        </div>


                                    </div>

                                </div>
                                <Search functionHandler={handleSearchChat}/>


                            </div>

                            <div className='col-md-4 col-lg-4 col-sm-12 '>
                                <div className=' pt-3 user-list-section'>
                                    <div className='lg-view'>
                                        <h5 className='text-light ml-5 mb-5`'>Messages</h5>
                                        <div className={`d-flex align-items-center`}>
                                            <Search functionHandler={handleSearchChat} props={'#13161A'}/>
                                            <button onClick={() => {
                                                setModalShow(true)
                                            }} style={{backgroundImage: `url(${plus})`}}
                                                    className="btn lg-view add-btn"></button>
                                            <button onClick={() => {
                                                setModalShow(true)
                                            }} style={{backgroundImage: `url(${plus})`}}
                                                    className="  btn-mobile"></button>
                                        </div>
                                    </div>


                                </div>
                                <div className='user-list'>
                                    {
                                        !loading && queryChats && queryChats.length > 0 ?
                                            queryChats.map(chat => {

                                                return (<>

                                                        {currentUser && <MessageCard key={chat.chatRoomId}
                                                                                     currentUser={currentUser}
                                                                                     id={chat.chatRoomId}
                                                                                     chats={chat}/>}


                                                    </>
                                                )
                                            })
                                            :
                                            !loading && chats && queryChats.length === 0 && !queryError  ? chats.map(chat => {

                                                    return (<>
                                                            {currentUser && <MessageCard key={chat.chatRoomId}
                                                                                         currentUser={currentUser}
                                                                                         id={chat.chatRoomId}
                                                                                         chats={chat}/>}

                                                        </>
                                                    )
                                                })
                                                :
                                                error ? <span
                                                        className={`text-light mx-auto text-center`}>{!loading && error && error}</span>
                                                    :
                                                    queryError && queryChats.length === 0 ? <p className={`text-light m-4 text-center`}>{queryError}</p>

                                                        :
                                                        loading  ?
                                                            <>
                                                                <MessageCardLoading />
                                                                <MessageCardLoading />
                                                                <MessageCardLoading />

                                                            </>
                                                            : <></>


                                    }

                                </div>


                            </div>


                        </div>

                    </div>


                </div>
            </div>
            <Footer addClassName={`${(show) && 'category'}`}/>
            {!show && <MobileNavbar />}
        </>

    );
}

export default Message;


