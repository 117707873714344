import logo from '../../images/logo.png';
import './footer.css';
import React, {useEffect,useState} from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

function Footer(props) {
    var location = useLocation();
    const [footerCSS, setFooterCSS] = useState({});

    useEffect(()=>{
        if(location.pathname != "/"){
            setFooterCSS({backgroundColor: '#13161a'});
        }
    },[location])

    return (
    <div className={`footer ${('addClassName' in props) ? props.addClassName : ''}`}style={footerCSS}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-12 col-lg-12">
                    <div className="row">

                        <div className="col-sm-12 col-md-3 footer-block footer-block1">
                            <a href="/" className="footer_logo">
                                <img alt="" src={logo}/>
                            </a>
                            <p>Because money is better than bragging rights.</p>
                            <a href="https://www.twitch.tv/clutchtimegaminginc" className="footer_social_link" style={{lineHeight: "35px"}} target="__blank__">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{width: "20px", height: "20px"}} viewBox="0 0 30 30"><path d="M 6 4 L 4 8 L 4 24 L 9 24 L 9 27 L 13 27 L 16 24 L 20 24 L 26 18 L 26 4 L 6 4 z M 8 6 L 24 6 L 24 17 L 21 20 L 15 20 L 12 23 L 12 20 L 8 20 L 8 6 z M 13 9 L 13 16 L 15 16 L 15 9 L 13 9 z M 17 9 L 17 16 L 19 16 L 19 9 L 17 9 z"></path></svg>
                            </a>
                            <a href="https://www.instagram.com/clutchtimegaminginc/" className="footer_social_link" style={{lineHeight: "35px"}} target="__blank__">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{width: "20px", height: "20px"}} viewBox="0 0 30 30"><path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path></svg>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100090329486897" className="footer_social_link" style={{lineHeight: "35px"}} target="__blank__">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.928" height="24.03" viewBox="0 0 12.928 24.03"><path d="M13.257,12.9V8.315h3.7V6.007A6.039,6.039,0,0,1,18.58,1.754,5.158,5.158,0,0,1,22.508,0h3.677V4.59H22.508a.81.81,0,0,0-.648.4,1.61,1.61,0,0,0-.288.973V8.314h4.613V12.9H21.571V24.03H16.958V12.9Z" transform="translate(-13.257)"/></svg>
                            </a>
                        </div>

                         <div className="col-sm-4 col-md-3 footer-block">
                            <h3 className="footer-header">About</h3>
                            <ul>
                                <li>
                                    <a href="about">Our Company</a>
                                </li>
                                <li>
                                    <a href="how-does-it-work">How does it work?</a>
                                </li>
                                <li>
                                    <NavLink exact={true} to={'/#contact_us'} href="#" >Contact us</NavLink>
                                    {/* <Link to={{pathname:"/", hash:'#contact_us'}}>Contact</Link> */}
                                    {/* <a href="#contact_us">Contact</a> */}
                                </li>
                            </ul>
                        </div><div className="col-sm-4 col-md-3 footer-block">
                            <h3 className="footer-header">Other</h3>
                            <ul>
                                <li>
                                    <a href="UseTerms">Terms of Use</a>
                                </li>
                                <li>
                                    <a href="PrivacyPolicy">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="col-md-12 col-lg-12 footer_copyright">
                    <p>Copyright © 2022, ClutchTime Inc.</p>
                </div>

            </div>
        </div>
    </div>
  );
}
export default Footer;






