import React, {useRef, useState} from 'react';
import '../sections/events/eventDetails.css'
import {useHistory} from 'react-router-dom'
import {useStateValue} from "../contexts/StateProvider";
import UserImage from '../images/default_profile_photo.svg';

const UserList = ({user, canBeSelected, multiSelect,handlerFunction}) => {
    const [selected, setSelected] = useState(false)
    const idRef = useRef();
    const history = useHistory()
    const [otherUser, setOtherUser] = useState()
    const [loading, setLoading] = useState(false)
    const [{ selectedParticipants}, dispatch] = useStateValue(); 
    //Send a message
    function handleSelect() {
        setLoading(true)
        if (multiSelect) {
            if (selected) {

                const index = selectedParticipants.findIndex(participant => participant.objId === idRef.current.id)
                if (index >= 0) {
                    selectedParticipants.splice(index, 1)
                }
                dispatch({
                    type: "REMOVE_UNSELECTED_PARTICIPANTS",
                    selectedParticipants
                })
                setSelected(false)

            } else {


                setOtherUser({
                    objectId: idRef.current.id,
                    email: idRef.current.getAttribute('email'),
                    gamerTag: idRef.current.getAttribute('gamerTag'),
                    // userProfileImage: idRef.current.getAttribute('userProfileImageUrl')
                })


                dispatch({
                    type: "SET_SELECTED_PARTICIPANTS",
                    selectedParticipants: {
                        objId: idRef.current.id,
                        email: idRef.current.getAttribute('email'),
                        gamerTag: idRef.current.getAttribute('gamerTag'),
                        // userProfileImage: idRef.current.getAttribute('userProfileImageUrl')
                    }
                })
                setSelected(true)


            }
        } else {
            if (selected) {
                setSelected(false)
            } else {
                setSelected(true)


                dispatch({
                    type: "SET_SELECTED_PRIVATE_CHAT_USER",
                    selectedUser: {
                        objId: idRef.current.id,
                        email: idRef.current.getAttribute('email'),
                        gamerTag: idRef.current.getAttribute('gamerTag'),
                    }
                })

                handlerFunction()

            }

        }


    }

    function handleClick() {
        history.push(`/user/${idRef.current.id}`)
    }

    return (
        <>
            {canBeSelected ?
                <>
                    <div style={{backgroundColor: selected ? '#2B3038' : 'initial'}} onClick={handleSelect} ref={idRef}
                         id={user.userId || user.objectId} gamerTag={user.gamerTag}
                         userProfileImageUrl={user.userProfileImageUrl} email={user.email}
                         className='d-flex pointer w-100 user-list-sub-section'>
                        <div className='user-list-thumb-wrapper' style={{textAlign: "center"}}>
                            <img src={!!user.userProfileImageUrl} alt="" style={{width: "inherit"}}/>
                            <img src={!!user?.userProfileImageUrl && user?.userProfileImageUrl !== "NONE" ? user.userProfileImageUrl:UserImage} alt="" style={{width: "inherit"}}/>
                            {/* <img src={user.userProfileImageUrl} alt="" style={{width: "inherit"}}/> */}
                        </div>
                        <div className='ml-3 text-light'>
                            <div className='space-medium'>{user?.gamerTag || user.userName}</div>
                            <div className="space-light">@{user?.gamerTag || user.userName}</div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div ref={idRef} id={user.userId || user.objectId || user.objId} gamerTag={user.gamerTag} onClick={handleClick}
                         userProfileImageUrl={user.userProfileImageUrl} email={user.email}
                         className='d-flex pointer w-100 user-list-sub-section'>
                        <div className='user-list-thumb-wrapper' style={{textAlign: "center"}}>
                            <img src={!!user?.userProfileImageUrl && user?.userProfileImageUrl !== "NONE" ? user.userProfileImageUrl:UserImage} alt="" style={{width: "inherit"}}/>
                            {/* <img src={user.userProfileImageUrl} alt="" style={{width: "inherit"}}/> */}
                        </div>
                        <div className='ml-3 text-light'>
                            <div className='space-medium'>{user?.gamerTag || user.userName}</div>
                            <div className="space-light">@{user?.gamerTag || user.userName}</div>
                        </div>
                    </div>
                </>
            }
        </>

    );
};

export default UserList;