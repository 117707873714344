import React, {useEffect, useState} from 'react';
import './userProfile.css'
import Header from "../header/header";
import Footer from "../footer/footer";
// import Graph from "../../components/graph";
import WinningRation from "../../components/winningRatio";
import Card from "../../components/card";
import MobileNavbar from "../../components/mobileNavbar";
import {useParams} from "react-router-dom";
import { useEvent } from '../../contexts/eventsContext';
import OtherUserMenu from "./otherUserMenu";
import {getDocWithRef, getRealtimeChild} from "../../utility/helper";
import {auth, db, realTimeDatabase} from "../../firebase/firebase";
import {useLoader} from "../../contexts/loaderContext";
import Sidebar from "../../components/sidebar";
import {useStateValue} from '../../contexts/StateProvider';
import {useAuthState} from "react-firebase-hooks/auth";
import Category from '../../components/category';
import Search from '../search/search';
import moment from 'moment';

function UserProfile() {
    const [currentUser] = useAuthState(auth)
    const [{user, otherUser}, dispatch] = useStateValue();
    const { queryError, setQueryError, queryEvents, setQueryEvents } = useEvent();
    let params = useParams();
    const [{ userData }] = useStateValue();
    const [joinedEvents, setJoinedEvents] = useState([]);
    const [eventSnap, setEventsSnap] = useState([]);
    const [categoryEventSnap, setCategoryEventsSnap] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const {setLoader, loader} = useLoader();
    let joinedEventsList = [];


    useEffect(() => {
        // console.log("Event Snap",eventSnap);

        onGetJoinedEventList()
        return () => {
            setQueryEvents([]);
            setQueryError('')
        }
    }, [params.id])

    const checkName = (name, str) => {
        var pattern = str.split("").map((x) => {
            return `(?=.*${x})`
        }).join("");
        var regex = new RegExp(`${pattern}`, "g")
        return name.match(regex);
    }

    async function handleSearch(e) {
        let searchList = [...eventSnap];
        var strSearch = e.target.value.toLowerCase();

        if (!!e.target.value && e.target.value.trim() != "") {
            setSearchLoading(true)
            const searchResults = searchList?.filter((x) => {
                if (x.ExhibitionName !== undefined) {

                    var xSub = x.ExhibitionName.substring(0, 4).toLowerCase();
                    return x.ExhibitionName.toLowerCase().includes(strSearch) || checkName(xSub, strSearch)
                }
            })

            if (searchResults.length > 0 && e.target.value !== "") {
                setQueryError('')
                // setEventsList(searchResults)
                setQueryEvents(searchResults);
                setSearchLoading(false)

            } else {
                setQueryEvents([]);
                setQueryError('No matches found')
                setSearchLoading(false)
            }
        }else{
            setQueryEvents([]);
            setQueryError('');
        }
    }

    async function onGetJoinedEventList() {
        let joinedEventsList = [];
        if ( user.uid !== params.id) {
            setLoader(true)


           const unsubscribe = db.collection('User').where('objectId', "==", params.id)
                .onSnapshot((snapshot) => {
                    const otherUser = snapshot.docs.map(doc => doc.data()).find(b => {
                        return b
                    })
                    dispatch({
                        type: "SET_OTHER_USER",
                        otherUser,
                    })

                });
            setLoader(false)

            return () => {
                // Detach listener when component unmounts
                unsubscribe()
            }
        } else if (user.uid) {
            //Get an array of participants that match the current user Id

            if(user?.email.includes('@clutchtime.com')){
                let clutchTimeCategory = [];
                await realTimeDatabase.ref('Exhibitions').orderByKey().get()
                .then((snapshot) => {
                    snapshot.forEach(doc => {
                        const data = doc.val()
                        Object.assign(data, { id: doc.key })
                        joinedEventsList.push(data)
                        clutchTimeCategory.push(Object.assign(doc, { id: doc.key }))
                    })
                    // setEventsList(searchList)
                    setEventsSnap(joinedEventsList)
                    setCategoryEventsSnap(clutchTimeCategory)
                    setLoading(false)
                }) 
                .catch(e => {
                    console.log(e)
                    // setLoading(false)
                })
            }
            else{
                getRealtimeChild('Participants', 'userId', user?.uid).get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {

                        // Fetch Events in each participants document using EventId field
                        
                        getDocWithRef('Exhibitions', doc.val().ExhibitionId)
                            .get()
                            .then((snapshot) => {
                                // Append the document key to the doc.val() object as an id property
                                joinedEventsList.push(Object.assign(snapshot?.val(), { id: snapshot.key }))

                                setEventsSnap((eventSnap) =>
                                    eventSnap.concat(Object.assign(snapshot?.val(), { id: snapshot.key })))
                                setCategoryEventsSnap((categoryEventSnap) =>
                                    categoryEventSnap.concat(Object.assign(snapshot, { id: snapshot.key })))
                                setLoading(false)
                            })
                            .catch(e => {
                                console.log(e)
                                setLoading(false)
                            })
                        // getDocWithRef('Exhibitions', doc.val().EventId)
                        //     .get()
                        //     .then((snapshot) => {
                        //         console.log("All Exhibitions Data: ",snapshot.val().ExhibitionName);
                        //         // Append the document key to the doc.val() object as an id property
                        //         joinedEventsList.push(Object.assign(snapshot?.val(), { id: snapshot.key }))

                        //         setEventsSnap((eventSnap) =>
                        //             eventSnap.concat(Object.assign(snapshot?.val(), { id: snapshot.key })))
                        //         setCategoryEventsSnap((categoryEventSnap) =>
                        //             categoryEventSnap.concat(Object.assign(snapshot, { id: snapshot.key })))
                        //         setLoading(false)
                        //     })
                        //     .catch(e => {
                        //         console.log(e)
                        //         setLoading(false)
                        //     })
                    })
                })
                .catch(e => {
                    console.log(e)
                    setLoading(false)
                })
            }
            setLoading(false)

        }
    }

    // useEffect(() => {

    //     //Get other user object from firestore User collection
    //     if ( user.uid !== params.id) {
    //         setLoader(true)


    //        const unsubscribe = db.collection('User').where('objectId', "==", params.id)
    //             .onSnapshot((snapshot) => {
    //                 const otherUser = snapshot.docs.map(doc => doc.data()).find(b => {
    //                     return b
    //                 })
    //                 dispatch({
    //                     type: "SET_OTHER_USER",
    //                     otherUser,
    //                 })

    //             });
    //         setLoader(false)

    //         return () => {
    //             // Detach listener when component unmounts
    //             unsubscribe()
    //         }
    //     } else {
    //         setLoading(true)

    //         // Get an array of participants that match the current user Id
    //         var i=1;
    //         console.log(user?.uid);
    //         getRealtimeChild('Participants', 'userId', user?.uid).get()
    //             .then(async (snapshot) => {
    //                 // Get event documents
    //                 snapshot.forEach((doc) => {
                        
    //                     // getDocWithRef('Exhibitions', doc.val().EventId)
    //                     //     .get()
    //                     //     .then( async(snapshot) => {
    //                     //         joinedEventsList.push(Object.assign(snapshot.val(), {id: snapshot.key}))
                                
    //                     //         // if(snapshot.val() !== undefined && snapshot.val() !== null && snapshot.val().ExhibitionCommissionerId !== currentUser?.uid){

    //                     //             setJoinedEvents((joinedEvents) =>
    //                     //                 joinedEvents.concat(Object.assign(snapshot.val(), {id: snapshot.key}))
    //                     //             )
    //                     //             setLoading(false)
    //                     //         // }

    //                     //     })
    //                     //     .catch(e => {
    //                     //         console.log(e)
    //                     //         setLoading(false)
    //                     //     })
                            
    //                         getDocWithRef('Exhibitions', doc.val().ExhibitionId)
    //                             .get()
    //                             .then( (snapshot) => {
    //                                 joinedEventsList.push(Object.assign(snapshot.val(), {id: snapshot.key}))
                                    
    //                                 // if(snapshot.val() !== undefined && snapshot.val() !== null && snapshot.val().ExhibitionCommissionerId !== currentUser?.uid){

    //                                     setJoinedEvents((joinedEvents) =>
    //                                         joinedEvents.concat(Object.assign(snapshot.val(), {id: snapshot.key})))
    //                                     setLoading(false)
    //                                 // }s

    //                             })
    //                             .catch(e => {
    //                                 console.log(e)
    //                                 setLoading(false)
    //                             })
    //                     })
                        
    //                 })
    //                 .catch(e => {
    //                     console.log(e)
    //                     setLoading(false)
    //                 })
    //             }
    //             setLoader(false)
    // }, [])


    return (
        <>

            <Header/>
            <>

                <>
                    {user && user.uid === params.id ?
                        <>
                            <div className='container user d-flex'>
                                <Sidebar/>
                                <div className={`lg-view flex-column`} style={{width: "100%"}}>
                                    {/* <Graph/> */}
                                    <WinningRation/>
                                    <div className='lg-view'>
                                        <div className='d-flex align-items-center pl-1 pr-1'>
                                            <h4 className=' text-light'>All Events</h4>

                                            <div className="search-container flex-grow-1">
                                                <div className='search d-flex float-right'>
                                                    <Search functionHandler={handleSearch} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='sm-view pl-4 pr-4'>
                                        <div className='  d-flex justify-content-center align-items-center pt-4'>
                                            <div className='flex-grow-1'>
                                                {
                                                    user ?
                                                        <h4 className='text-light'>Hi {user?.displayName || userData?.gamerTag},</h4>
                                                        :
                                                        <h4 className='text-light'>All Exhibitions</h4>
                                                }
                                                <p>Let's Discover a new Adventure</p>
                                            </div>

                                            <div className="search-container flex-grow-1">
                                                <div className='search d-flex float-right'></div>
                                            </div>

                                        </div>
                                        <Search functionHandler={handleSearch} />

                                    </div>
                                    <Category event={categoryEventSnap} />

                                    {queryError && queryEvents.length === 0 && <p className={`text-light text-center`}>{queryError}</p>}
                                    <div className='grid-container'>
                                        <>

                                            {
                                            
                                            loading || searchLoading ? <>
                                                        <Card event={''} key={1} />
                                                        <Card event={''} key={2} />
                                                        <Card event={''} key={3} />
                                                    </>
                                            
                                            :
                                            
                                            queryEvents?.length > 0 && !searchLoading ? queryEvents.map(event => {
                                                // if (moment(Date.now()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(Date.now()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                                    return (
                                                        <>
                                                        {event !== undefined && event !== null &&
                                                        <Card event={event} id={event.key} key={event.id} redirectURL={event.ExhibitionStyle == 'Exhibition' || event.ExhibitionStyle == 'Tournament'?`/user/${user?.uid}/tournament/${event.id}`:null} />}
                                                        </>
                                                        
                                                    )
                                                // }
                                            })
                                                :
                                                eventSnap&&queryError == "" ? eventSnap.map((event, index) => {
                                                    // if (moment(moment().valueOf()).isSameOrAfter(event.ExhibitionStartDate, 'day') && moment(moment().valueOf()).isSameOrBefore(event.ExhibitionEndDate, 'day')) {
                                                        return (
                                                            
                                                            <>
                                                            {event !== undefined && event !== null &&
                                                            <Card event={event} id={event.id} key={event.id} redirectURL={event.ExhibitionStyle == 'Exhibition' || event.ExhibitionStyle == 'Tournament'?`/user/${user?.uid}/tournament/${event.id}/`:null} />}
                                                            </>
                                                                
                                                        )
                                                    // }
                                                })
                                                    
                                                :
                                                <>
                                                </>

                                            }
                                        </>

                                    </div>

                                </div>

                            </div>
                        </>

                        :
                        <>
                            <div className='container other-user d-flex'>
                                <>
                                    {!loader && user.uid && otherUser?.objectId &&
                                    <OtherUserMenu otherUserObj={otherUser}/>}

                                </>

                            </div>

                        </>}
                </>
                <MobileNavbar/>

            </>
            <Footer/>
        </>
    );
}


export default UserProfile;