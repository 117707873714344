import React, {useRef, useState} from "react"
import mail from "../images/mail.svg";
import {auth} from '../firebase/firebase';
import PuffLoader from "react-spinners/PuffLoader";
import {css} from "@emotion/react";

export default function ForgotPassword(props) {
    const emailRef = useRef()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const override = css`
      display: block;
      margin: 0 7%;
      margin-left: auto;
      margin-right: auto;`;
    let [color, setColor] = useState("#18ff00");
    // let setLoginStatus = props.setLoginStatus();

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        try {
            setMessage("")
            setError("")
            await auth.sendPasswordResetEmail(emailRef.current.value)
                .then(() =>{
                    setLoading(false)

                })
                .catch(e =>{
                    console.log(e.message)
                })
            
            setMessage("Check your inbox for further instructions")
            props.setLoginStatus("false")
        } catch {
            setError("Failed to reset password")
            
        }
        setLoading(false)

    }

    async function handleClick(){
        props.onHide()
    }

    return (
        <>

            <form className="form" onSubmit={handleSubmit}>
                <p className="text-danger">{error}</p>
                <p className="text-success" style={{fontSize: "18px"}}>{message}</p>
                {
                (message=="")?
                    <div>
                        <div className="input-group">
                        <input ref={emailRef} name="email" style={{backgroundImage: `url(${mail})`}} type="Email"
                                placeholder="Email"/>

                        </div>

                        <button disabled={loading} style={{background: loading ? '#ffffff' : ''}} type="submit">{(loading)?<PuffLoader css={override}  loading={loading} size={24} color={color} />:<span className='form-btn'>Send Link</span>}</button>
                    </div>
                :
                // <></>
                    <button onClick={handleClick} type="button"><span className='form-btn'>Okay</span></button>
                }
            </form>
            
        </>
    )
}