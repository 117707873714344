import React, {useContext, useState} from 'react'

const EventContext = React.createContext()

export function useEvent() {
    return useContext(EventContext)
}

export function EventProvider({ children }) {
    const [eventsList,setEventsList] = useState(null);
    const [queryEvents,setQueryEvents] = useState([]);
    const [queryError,setQueryError] = useState('');

    const value = {
        eventsList,
        setEventsList,
        queryEvents,setQueryEvents,
        queryError,setQueryError
    }

    return (
        <EventContext.Provider value={value}>
            { children}
        </EventContext.Provider>
    )
}
