import React, { useEffect, useState } from 'react';
import Header from "../sections/header/header";
import Sidebar from "./sidebar";
import axios from "axios";
import { pushRealTimeData, updateFirebaseDocument, updateFirestoreDocument } from "../utility/helper";
import wallet from "../images/wallet.svg";
import withdraw from "../images/withdraw.png";
import TransactionCard from "./transactionCard";
import BackButton from "./backButton";
import { useStateValue } from '../contexts/StateProvider';
import { useList } from "react-firebase-hooks/database";
import { useForm } from "../contexts/formContext";
import { realTimeDatabase } from "../firebase/firebase";
import TransactionCardLoading from "./transactionCardLoading";
import { ModalComponent } from "../sections/modal/modal";

function Withdraw() {
    const [{ user, userData }] = useStateValue()

    const [clickedWithdraw, setClickedWithdraw] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transactionSnap] = useList(realTimeDatabase.ref('Transaction').orderByChild('userId').equalTo(user?.uid))

    const { setFormType } = useForm()
    const [modalShow, setModalShow] = useState(false);
    const [amount, setAmount] = useState(0)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')



    const handleWithdrawProcess = (e) => {

        if (amount && amount > userData.userBalance) {
            setError('Withdrawal amount exceeds your balance')
        }
        else if (amount <= 0) {
            setError('Withdrawal amount cannot be less than or equal to $0')
        }
        else {
            e.preventDefault()
            setFormType('withdrawForm')

            setModalShow(true)
        }
    }

    useEffect(() => {
        setError('')
        setSuccess('')


    }, [])
    return (
        <>
            <Header />
            <div className={` withdraw userData container`}>
                <div className={`d-flex `}>
                    <div className={`lg-view`}>

                        <Sidebar />
                    </div>

                    <div className='text-light flex-column d-flex flex-grow-1 align-items-center justify-content-center'>
                        <div className={`mt-4 mr-auto`}>
                            <BackButton />
                        </div>
                        <div className={`icon-wrapper`}>
                            <img src={wallet} alt="" />

                        </div>
                        <h3>$ {userData?.userBalance}</h3>
                        <h5 className={``}>Total balance</h5>
                        <p className={`text-danger`}>{error}</p>
                        <p className={`text-success`}>{success}</p>
                        <form action="">
                            <input className={`${error === "" ? 'text-light' : 'text-danger'}`} onChange={(e) => {
                                setAmount(parseFloat(e.target.value).toFixed(2))
                            }} value={amount} placeholder={`Amount in USD`} type="number" />
                        </form>

                        <button disabled={loading} onClick={handleWithdrawProcess}
                            style={{ backgroundImage: `url(${withdraw})` }}
                            className={`btn w-50  text-danger border-danger icon-btn btn-clear`}>
                            {!loading ? 'Withdraw' : 'Loading...'}
                        </button>

                        <div className='menu-options transactions container-fluid'>
                            {!loading && transactionSnap ? transactionSnap?.reverse().map((transaction) => {
                                return (

                                    transaction.val().transactionType === 'Withdrawal' && <TransactionCard image={withdraw} details={transaction.val()} key={transaction.val().transactionId} />
                                )
                            })
                                : <>
                                    <TransactionCardLoading image={withdraw} />
                                    <TransactionCardLoading image={withdraw} />
                                    <TransactionCardLoading image={withdraw} />
                                    <TransactionCardLoading image={withdraw} />

                                </>
                            }


                        </div>

                        <ModalComponent
                            amount={amount}
                            show={modalShow}
                            onHide={() => {
                                setAmount(0);
                                setModalShow(false);
                            }}
                        />

                    </div>
                </div>

            </div>

        </>);
}


export default Withdraw;