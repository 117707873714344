import React, {useState} from 'react'
import {useHistory} from "react-router-dom";
import firebase from "firebase";
import {db} from "../firebase/firebase";
import {useStateValue} from '../contexts/StateProvider';

function UserContact({id, email, gamerTag, userProfileImageUrl}) {

    const [{user,userData}] = useStateValue()
    const history = useHistory()
    const [ loading,setLoading] = useState(true);
    const [selected,setSelected] = useState(false)

    const createPrivateChatRoom = () => {

        setSelected(true)
        setLoading(true)



        if(userData.email && email ){
            try {
            db.collection('ChatRooms')
                .doc(user.uid+id)
                .get()
                .then(snapshot =>{
                    if (snapshot.exists)
                        history.push(`/messages/${snapshot.id}`)
                    else {
                        const data = {
                            dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                            groupChatName: '',
                            groupImageUrl: '',
                            isGroupChat: false,
                            participants: [
                                {
                                    objId: user.uid,
                                    email: userData.email,
                                    userName:userData.gamerTag,
                                    userProfileImage:userData.userProfileImageUrl
                                },
                                {
                                    objId: id,
                                    email: email,
                                    userName: gamerTag,
                                    userProfileImage: userProfileImageUrl
                                }
                            ],
                            chatRoomId: user.uid+id
                        }

                        db.collection("ChatRooms").doc(data.chatRoomId).set(data)
                            .then(async () => {
                                await data.participants.map(each => {
                                    db.collection('User').doc(each.objId).collection('ChatRoomIds').add(
                                        {
                                            id: data.chatRoomId,
                                            isDeleted: false,
                                            isDelivered: true,
                                        })
                                })
                                // Redirect to the newly created chatRoom  endpoint

                                setLoading(false)

                                setSelected(false)
                                history.push(`/messages/${data.chatRoomId}`)

                            })
                            .catch((error) => {
                                setLoading(false)
                                setSelected(false)
                                console.error("Error adding document: ", error);
                            });
                    }
                })
                .catch(e =>{
                    console.log(e.message)
                    setLoading(false)
                    setSelected(false)
                })

            }
            catch (e) {
                console.log(e)
                setLoading(false)
                setSelected(false)
            }


        }


    }

    return (

            <div onClick={createPrivateChatRoom} style={{backgroundColor: selected ? '#2B3038' : 'initial'}}  className='d-flex pointer user-list-sub-section'>
                <div className='user-list-thumb-wrapper'>
                    <img src={userProfileImageUrl} alt=""/>
                </div>
                <div className='ml-3 text-light'>
                    <div className='space-medium'>{gamerTag}</div>
                    <div className="space-light">@{gamerTag}</div>
                </div>
            </div>

    )
}

export default UserContact