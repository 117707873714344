import React, { useEffect, useState } from 'react';
import '../sections/events/events.css'
import { useEvent } from "../contexts/eventsContext";
import { useLoader } from "../contexts/loaderContext";

function RoundCategory({ event, onChangeTab, activeTab, roundWinner, exhibition }) {
    const { setQueryEvents, setQueryError } = useEvent()
    const [eventList, setEventList] = useState(event || []);
    const [exhibitionObj, setExhibition] = useState(exhibition || {});
    const [roundWinnerObj, setRoundWinner] = useState(roundWinner || {});
    const { setLoader } = useLoader()
    // let eventList = [];

    // const eventList = [
    //     { id: 1, text: 'All' },
    //     { id: 2, text: 'Madden' },
    //     { id: 5, text: 'NBA 2K' }
    // ];

    const [activeId, setActiveId] = useState(activeTab || "round_1");

    function handleClickCategory(e) {
        // setLoader(true);
        const value = e.target.value;
        setActiveId(value);
        if (onChangeTab) {
            onChangeTab(value)
        }
    }

    useEffect(() => {
        setEventList(event)
        setActiveId(activeTab)
        setRoundWinner(roundWinner)
    }, [event, activeTab, roundWinner])

    return (
        <nav className='mt-4 category mb-4 navbar navbar-expand-lg categoryRound'>
            <div className="" style={{overflowX:'auto'}}>
                <ul className="navbar-nav ml-auto d-flex flex-row flex-nowrap flex-overflow py-2 border-0" style={{overflow:'auto', whiteSpace: 'nowrap', width: '100%', display:'inline-block' }}>

                {eventList && eventList.map((val, key) => 
                        {
                            return(
                            (key > 0) ? 
                                (roundWinnerObj[eventList[key-1]] > 0) ? 
                                    <li key={val} className={`d-inline-block nav-item Inactive`}>
                                        <button className={`btn btn-clear Inactive text-capitalize`}>{val.replace('_', ' ')}</button>
                                    </li>
                                :
                                    (exhibitionObj.ExhibitionMaximumParticipants == exhibitionObj.ExhibitionCurrentParticipants) ? 

                                        <li key={val} onClick={() => setActiveId(val)} className={`d-inline-block nav-item ${activeId === val ? "active" : "Inactive"}`}>
                                            <button className={`btn btn-clear ${activeId === val ? "active" : "Inactive"} text-capitalize`} onClick={handleClickCategory} value={val}>{val.replace('_', ' ')}</button>
                                        </li>

                                    :
                                    <li key={val} className={`d-inline-block nav-item Inactive`}>
                                        <button className={`btn btn-clear Inactive text-capitalize`}>{val.replace('_', ' ')}</button>
                                    </li>
                            :   
                                <li key={val} onClick={() => setActiveId(val)} className={`d-inline-block nav-item ${activeId === val ? "active" : "Inactive"}`}>
                                    <button className={`btn btn-clear ${activeId === val ? "active" : "Inactive"} text-capitalize`} onClick={handleClickCategory} value={val}>{val.replace('_', ' ')}</button>
                                </li>
                            )
                        }
                    )}
                </ul>
            </div>
        </nav>

    );
}

export default RoundCategory;