import './performance.css';
import performace_img from '../../images/homescreenPromotional.png';

export default function Performance() {
  return (
    <div className="performance">
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-sm-5 col-lg-4 order-2 order-sm-1 performance_image_wraper">
                    <img alt="" src={performace_img}/>
                </div>
                <div className="col-sm-7 col-lg-5 order-1 order-sm-2 performance_text">
                    <h3>Create<br/>an event and organize it or allow one of our commissioners to handle the logistics.</h3>
                    <p>By default we add commissioners to every event in order to assist the creator maintaining the event and making sure everything is fair. We also will handle any grievances.</p>
                    <a href="#" className="btn">Download App</a>
                </div>
            </div>
        </div>
    </div>
  );
}
