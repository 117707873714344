import React, { useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import '../sections/profile/userProfile.css'
import Bar from "./bar";
import { useParams } from "react-router-dom";
import { useStateValue } from '../contexts/StateProvider';
import { css } from "@emotion/react";
import copy from "../images/copy.svg";
import copied from "../images/copied.svg";
import { StepConnector } from '@material-ui/core';

function WinningRation() {
    const [{ user, userData }] = useStateValue()
    let params = useParams();
    // console.log("User data++++++++++++", userData);
    const override = css`
    display: block;
    margin: 1.7% 7%;
    margin-left: 5px;
    margin-right: auto;`;
    let [color, setColor] = useState("#18ff00");
    let [copyIcon, setCopyIcon] = useState(copy);

    const copyToClipboard = (referralCode)=>{
        if(referralCode && referralCode != ""){

            navigator.clipboard.writeText(referralCode);
            setCopyIcon(copied);

            setTimeout(() => {
                // console.log('Hello, World!')
                setCopyIcon(copy);
            }, 3000);
        }
    }


    return (
        <div className="graph p-4" style={{minWidth: '30vw', marginBottom: '4%'}}>
            <div className={`d-flex text-light`}>
                <h6>Account balance</h6>{
                    params.id === user?.uid && <span className={`ml-auto`} style={{ fontSize: 16 }}>{userData ? '$ ' + (userData?.userBalance || '0') : <PuffLoader css={override} loading={true} size={15} color={color} />}</span>
                }

            </div>
            <div className={`d-flex text-light`}>
                <h6>Invite Credits balance</h6>{
                    params.id === user?.uid && <span className={`ml-auto`} style={{ fontSize: 16 }}>{userData ?  (userData?.InviteCredits) ? (userData.InviteCredits != "") ? '$ ' +userData.InviteCredits : '$ ' +'0' : '$ ' +'0' : <PuffLoader css={override} loading={true} size={15} color={color} />}</span>
                }

            </div>
            <div className={` d-flex text-light`}>
                {
                    !!userData ?
                        <div><h6>Wins:  {userData && userData.userWins}</h6> </div>
                        :
                        <div style={{ display: 'inline-flex', justifyContent: "center" }}><h6>Wins:</h6> <PuffLoader css={override} loading={true} size={15} color={color} /></div>
                }
                {
                    !!userData ?
                        <div style={{ flexGrow: 2, textAlign: "right" }}><h6>Losses:  {userData && userData.userLosses}</h6> </div>
                        :
                        <div style={{ display: 'inline-flex', justifyContent: "flex-end", textAlign: "right", flexGrow: 2 }}><h6>Losses:</h6> <span style={{ paddingTop: 5 }}><PuffLoader css={override} loading={true} size={15} color={color} /></span></div>
                }
            </div>
            {/* <div className={` d-flex text-light`} style={{width: "100%"}}>
                <h6 className='mr-1' style={{width: "20%", textAlign: "left"}}>Invite Code:</h6>{
                    params.id === user?.uid && <span style={{ fontSize: 13, color: "white", width: "75%", overflowWrap: "break-word" }}>{userData ? (userData?.userReferralCode || '-') : <PuffLoader css={override} loading={true} size={15} color={color} />}</span>
                }
                <span className={`ml-auto`} style={{ color: "white", overflowWrap: "break-word", textAlign:"right", cursor:"pointer"}} onClick={()=>{copyToClipboard(userData?.userReferralCode);}}><img src={copyIcon} /></span>
            </div> */}

        </div>
    );
}

export default WinningRation;