import React from 'react';
import Text from "./text";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase/firebase";

function ChatContent({message, participants}) {
    const [currentUser] = useAuthState(auth)



    return (
        <>
            <div className={`d-flex mb-3 text-container ${currentUser && currentUser.uid === message.senderObjId ? 'user-text': 'guest-text'}`} >
                <div className='user-list-thumb-wrapper align-self-end'>
                    <img src={message.userProfileImageUrl} alt=""/>
                </div>
                <Text message={message} participants={participants} preview={''}/>


            </div>

        </>

    );
}

export default ChatContent;
