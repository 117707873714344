import React, {useEffect, useState} from 'react';
import rank from "../../images/rank.svg";
import follow from "../../images/follow.svg";
import unFollow from "../../images/unfollow.png";
import message from "../../images/message_green.svg";
import {useHistory} from "react-router-dom";
// import Graph from "../../components/graph";
import {getRealtimeChild, getRealtimeDoc, updateFirestoreDocument} from "../../utility/helper";
import Card from "../../components/card";
import firebase from "firebase";
import {db} from "../../firebase/firebase";
import defaultProfilePhoto from "../../images/default_profile_photo.svg";
import {useLoader} from "../../contexts/loaderContext";
import BackButton from "../../components/backButton";
import {useStateValue} from '../../contexts/StateProvider';
import WinningRation from "../../components/winningRatio";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import { ModalComponent } from "../modal/modal";
import { useForm } from "../../contexts/formContext";


function OtherUserMenu({otherUserObj}) {
    const [{user, userData, otherUser, hasFollowed}, dispatch] = useStateValue()

    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { setFormType } = useForm();

    const {setLoader, loader} = useLoader();
    const history = useHistory()
    const [joinedEvents, setJoinedEvents] = useState([]);
    const [messageModalShow, setMessageModalShow] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState(false);
    let userJoinedEventsList = [];
    let joinedEventsList = [];

    const override = css`
    display: block;
    margin: 0 7%;
    margin-left: auto;
    margin-right: auto;`;
    let [color, setColor] = useState("#ffffff");
	

    useEffect(() => {
        dispatch({
            type: "SET_HAS_FOLLOWED",
            hasFollowed: false
        })
        setLoader(true)

        otherUserObj.FollowersIds?.find((follower) => {

            if (user && user.uid === follower) {
                dispatch({
                    type: "SET_HAS_FOLLOWED",
                    hasFollowed: true
                })

                return true;
            } else {
                dispatch({
                    type: "SET_HAS_FOLLOWED",
                    hasFollowed: false
                })
                return false;

            }
        })


    }, [])
    useEffect(() => {


        setLoading(true)

        {
            otherUserObj.objectId &&
            //Get an array of participants that match the other user Id
            getRealtimeChild('Participants', 'userId', otherUserObj.objectId).get()
                .then(async(snapshot) => {
						
                    if (snapshot.exists()){
						
                        snapshot.forEach(async (doc) => {
                            await userJoinedEventsList.push(doc.val())
				
                        })
                    }
                    else {

                        setLoading(false)
                    }

                    //Get an array of Events in the participants array
                    userJoinedEventsList?.map((eventJoined) => {
                        getRealtimeDoc('Exhibitions', eventJoined.ExhibitionId)
                            .then(async (snapshot) => {
                                if (snapshot.exists()){
                                    await joinedEventsList.push(Object.assign(snapshot.val(), {id: snapshot.key}))
                                    await setJoinedEvents(joinedEventsList)
                                }
                                else {
                                    setLoading(false)
                                }



                            })


                            .catch(e => {
                                console.log(e)
                                setLoading(false)

                            })

                    })

                })
                .catch(e => {
                    console.log(e.message)
                    setLoading(false)

                })

            setLoading(false)
        }

        setLoader(false)

    }, [otherUserObj.objectId])


    // This function is called when the message button is clicked
    // This function creates a private/one-to-one chatroom
    const handleMessage = async () => {


        if (userData.email && otherUser?.objectId) {
            try {
                // Add chatroom document to ChatRooms collection
                db.collection('ChatRooms')
                    .doc(user.uid + otherUser.objectId)
                    .get()
                    .then(snapshot => {
                        // Check if chatroom snapshot exists
                        if (snapshot.exists)
                            history.push(`/messages/${snapshot.id}`)
                        else {
                            const data = {
                                dateLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                                groupChatName: '',
                                groupImageUrl: '',
                                isGroupChat: false,
                                participants: [
                                    {
                                        objId: user.uid,
                                        email: userData.email,
                                        userName: userData.gamerTag,
                                        userProfileImage: userData.userProfileImageUrl
                                    },
                                    {
                                        objId: otherUser.objectId,
                                        email: otherUser.email,
                                        userName: otherUser.gamerTag,
                                        userProfileImage: otherUser.userProfileImageUrl
                                    }
                                ],
                                chatRoomId: user.uid + otherUser.objectId
                            }

                            db.collection("ChatRooms").doc(data.chatRoomId).set(data)
                                .then(() => {

                                    // Redirect to the newly created chatRoom  endpoint
                                    db.collection('User').doc(user.uid).collection('ChatRoomIds').add(
                                        {
                                            id: data.chatRoomId,
                                            isDeleted: false,
                                            isDelivered: true,
                                        }).then(() => {
                                        setLoading(false)

                                        history.push(`/messages/${data.chatRoomId}`)
                                    }).catch((error) => {
                                        console.log(error.message)
                                        setLoading(false)
                                    })
                                })
                                .catch((error) => {
                                    setLoading(false)
                                    console.error(error.message);
                                });
                        }
                    })
                    .catch(e => {
                        console.log(e.message)
                        setLoading(false)
                    })

            } catch (e) {
                console.log(e)
                setLoading(false)
            }


        }


    }
    //Follow a user
    const handleFollow = async () => {
        setIsLoading(true);

        setFormType('messageModal');
        setMessageModalShow(true);
        setLoaderMessage("Following...");

        try {

            await updateFirestoreDocument('User', user.uid, {followedUsersIds: firebase.firestore.FieldValue.arrayUnion(otherUser.objectId)})
                .then(() => {
                    dispatch({
                        type: "SET_HAS_FOLLOWED",
                        hasFollowed: true
                    })


                    updateFirestoreDocument('User', otherUser.objectId, {FollowersIds: firebase.firestore.FieldValue.arrayUnion(user.uid)})
                        .then(() => {
                            setIsLoading(false)

                            setTimeout(() => {
                                setMessageModalShow(false);
                            }, 3000);

                        })
                        .catch(error => {
                            console.log(error);
                            dispatch({
                                type: "SET_HAS_FOLLOWED",
                                hasFollowed: false
                            })
                            setIsLoading(false)

                            setTimeout(() => {
                                setMessageModalShow(false);
                            }, 3000);
                        })
                })

                .catch(e => {
                    console.log(e)
                    dispatch({
                        type: "SET_HAS_FOLLOWED",
                        hasFollowed: false
                    })
                    setIsLoading(false)

                    setTimeout(() => {
                        setMessageModalShow(false);
                    }, 3000);

                })

        } catch (e) {
            console.log(e)
            dispatch({
                type: "SET_HAS_FOLLOWED",
                hasFollowed: false
            })
            setIsLoading(false)

            setTimeout(() => {
                setMessageModalShow(false);
            }, 3000);

        }
        // setIsLoading(false)

    }

    //Unfollow a user
    const handleUnfollow = async () => {
        setIsLoading(true);

        setFormType('messageModal');
        setMessageModalShow(true);
        setLoaderMessage("Unfollowing...");
        try {

            await updateFirestoreDocument('User', user.uid, {followedUsersIds: firebase.firestore.FieldValue.arrayRemove(otherUser.objectId)})
                .then(() => {
                    dispatch({
                        type: "SET_HAS_FOLLOWED",
                        hasFollowed: false
                    })

                    updateFirestoreDocument('User', otherUser.objectId, {FollowersIds: firebase.firestore.FieldValue.arrayRemove(user.uid)})
                        .then(
                            () => {
                                dispatch({
                                    type: "SET_HAS_FOLLOWED",
                                    hasFollowed: false,
                                })
                                setIsLoading(false)

                                setTimeout(() => {
                                    setMessageModalShow(false);
                                }, 3000);
                            }
                        )
                        .catch(error => {
                            console.log(error);
                            dispatch({
                                type: "SET_HAS_FOLLOWED",
                                hasFollowed: true
                            })
                            setIsLoading(false)

                            setTimeout(() => {
                                setMessageModalShow(false);
                            }, 3000);
                        })
                })

        } catch (e) {
            console.log(e)
            setIsLoading(false)

            setTimeout(() => {
                setMessageModalShow(false);
            }, 3000);
        }
        // setIsLoading(false)
    }

    return (
        <>
            {
                (messageModalShow)&&
                    <ModalComponent show={true} title="" type="message" message={loaderMessage} buttonStatus={false} />
            }


            <div className='d-flex user-menu-container'>
                <div className='d-flex flex-column text-center card-body user-side-bar' style={{height: "auto"}}>
                    <div className='text-left w-100 '>
                        <BackButton/>
                    </div>
                    <div className='text-center  user-info-container'>

                        <div className='position-relative d-flex align-items-center '>
                            <div className='sm-view'>
                                <div className=' other-user-btn'>
                                    <button onClick={handleMessage} className='btn flex-grow-1 m-2 btn-clear'
                                            style={{backgroundImage: `url(${message})`}}></button>

                                </div>
                            </div>

                            {otherUser && otherUser.userProfileImageUrl
                                ?
                                <div className='mx-auto user-profile-pic-wrapper'
                                     style={{backgroundImage: `url(${otherUser.userProfileImageUrl})`}}></div>


                                :
                                <div className='mx-auto user-profile-pic-wrapper'
                                     style={{backgroundImage: `url(${defaultProfilePhoto})`}}></div>


                            }
                            {/*<img src={otherUser.userProfileImageUrl} alt=""/>*/}
                            <div className='badge-wrapper'>
                                <img src={rank} alt=""/>
                                <span className='ml-2'>0</span>
                            </div>
                            <div className='sm-view'>
                                <div className=' other-user-btn'>

                                    <>
                                        {
                                            !isLoading && hasFollowed
                                                ?
                                                <button disabled={loader} onClick={handleUnfollow}
                                                        className='btn flex-grow-1 m-2'
                                                        style={{backgroundImage: `url(${unFollow})`}}></button>

                                                : !isLoading && !hasFollowed ?
                                                <button disabled={loader} onClick={handleFollow}
                                                        className='btn flex-grow-1 m-2'
                                                        style={{backgroundImage: `url(${follow})`}}></button>

                                                :
                                                <>
                                                    {isLoading && <button className='btn flex-grow-1 m-2 p-0'>
                                                                    <PuffLoader css={override} loading={true} size={24} color={color} />
                                                                </button>}

                                                </>
                                        }

                                    </>


                                </div>
                            </div>


                        </div>


                        <div className='mt-5 mb-4 text-light'>
                            <div className='space-medium f-18'>{otherUser.gamerTag}</div>
                            <div className="space-light ">@{otherUser.gamerTag}</div>


                        </div>

                        <div className={`sm-view`}>
                            {/* <Graph/> */}
                            <WinningRation/>
                        </div>

                    </div>
                    <div className='p-3  center  followers-container text-light mb-4'>
                        <div className='flex-column border-right d-inline-flex text-center flex-grow-1 follow-stats'>
                            <span>{otherUser.FollowersIds && otherUser.FollowersIds.length}</span>
                            <span>Followers</span>
                        </div>
                        <div className='flex-column d-inline-flex text-center flex-grow-1 follow-stats'>
                            <span>{otherUser.followedUsersIds && otherUser.followedUsersIds.length}</span>
                            <span>Following</span>
                        </div>

                    </div>
                    <div className='lg-view'>
                        <div className='   center other-user-btn'>
                            <button onClick={handleMessage} className='btn flex-grow-1 m-2 btn-clear'
                                    style={{backgroundImage: `url(${message})`}}>Message
                            </button>

                            <>
                                {
                                    !isLoading && hasFollowed
                                        ?
                                        <button onClick={(e)=>{e.target.blur();handleUnfollow();}}
                                                className='btn flex-grow-1 m-2'
                                                style={{backgroundImage: `url(${unFollow})`}}>Unfollow</button>

                                        : !isLoading && !hasFollowed ?
                                        <button onClick={(e)=>{e.target.blur();handleFollow();}}
                                                className='btn flex-grow-1 m-2'
                                                style={{backgroundImage: `url(${follow})`}}>Follow</button>

                                        :
                                        <>
                                            {isLoading && <button className='btn flex-grow-1 m-2'>
                                                                <PuffLoader css={override} loading={true} size={24} color={color} />
                                                            </button>}

                                        </>
                                }

                            </>


                        </div>
                    </div>


                </div>
                <div className={`flex-column`}>
                    <h4 className={`text-light d-block ml-1`}>Activity</h4>
						

                    <div className='grid-container'>
                        {!loading && joinedEvents ? joinedEvents?.map(event => {
                            return (
                                <>

                                    <Card event={event} id={event.id} key={event.id}/>
                                </>
                            )
                        }) :
                            isLoading ?
                            <>
                                <Card event={''}/>
                                <Card event={''}/>
                                <Card event={''}/>
                                <Card event={''}/>
                            </>
                            :
                            !loading && joinedEvents.length === 0 ?
                            <>
                                <p className={`mx-auto text-center justify-content-center align-items-center text-light`}>No recent activity</p>

                            </>
                            : <>

                        </>}
                    </div>
                </div>


            </div>


        </>
    );
}

export default OtherUserMenu;