import React from 'react';
import Header from "../header/header";

function Contact() {
    return (
        <div>
            <Header />
        </div>
    );
}

export default Contact;