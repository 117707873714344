
export const initialState = {
    // States

    user:null,
    userData:null,
    otherUser:null,
    hasJoined:false,
    hasFollowed:false,
    selectedUser:null,
    joinedEvents:[], 
    createdEvents:[],
    selectedParticipants:[],
   }

   const reducer = (state, action) =>{
     switch(action.type){
         case "SET_USER":
             return{
                 ...state,
                 user: action.user
             }
         case "SET_USER_DATA":
              return{
                  ...state,
                  userData: action.userData
              }
         case "SET_HAS_JOINED":
             return{
                 ...state,
                 hasJoined:action.hasJoined
             }
         case "SET_CREATED_EVENTS":
             return{
                 ...state,
                 createdEvents:[...state.createdEvents,action.createdEvents]
             }
         case "SET_OTHER_USER":
             return{
                 ...state,
                 otherUser:action.otherUser
             }
         case "SET_JOINED_EVENTS":
             return{
                 ...state,
                 joinedEvents:[...state.joinedEvents,action.joinedEvents ]
             }
         case "SET_SELECTED_PARTICIPANTS":
             return{
                 ...state,
                 selectedParticipants:[...state.selectedParticipants,action.selectedParticipants ]
             }
         case "REMOVE_UNSELECTED_PARTICIPANTS":
             console.log(action.selectedParticipants)
             return {
                 ...state,
                 selectedParticipants:action.selectedParticipants
             }
             case "REMOVE_SELECTED_PARTICIPANTS":
             console.log(action.selectedParticipants)
             return {
                 ...state,
                 selectedParticipants:[]
             }
         case "SET_SELECTED_PRIVATE_CHAT_USER":
             return{
                 ...state,
                 selectedUser:action.selectedUser
             }
         case "SET_HAS_FOLLOWED":
             return{
                 ...state,
                 hasFollowed: action.hasFollowed
             }
       default:
          return state;
          
     }
   } 
   
   export default reducer;