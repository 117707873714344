import React, {useState} from 'react';
import '../sections/events/events.css'
import {useEvent} from "../contexts/eventsContext";
import {useLoader} from "../contexts/loaderContext";
import {realTimeDatabase} from "../firebase/firebase";

function Category({event}) {
    const {setQueryEvents,setQueryError} = useEvent()
    const { setLoader} =useLoader()

    let eventList = []
    const values = [
        { id: 1, text: 'All' },
        { id: 2, text: 'Madden' },
        { id: 3, text: 'NBA 2K' },
        { id: 4, text: 'Pokemon Scarlet and Violet' },
    ];

    const [activeId, setActiveId] = useState();
    function handleClickCategory(e) {
        setLoader(true);
        let eventsRef;
        if(e.target.value === 'All' ){
            setQueryEvents([...event].reverse().map(each => each.val()))
            setQueryError('')
            setLoader(false);
        }
        else{
            // eventsRef = realTimeDatabase.ref('Exhibitions').orderByChild('ExhibitionGame');
            // eventsRef.on('value',(snapshot) => {
                // if(snapshot.exists()){
                    // snapshot.forEach(function(events) {
                        [...event].reverse().map(each => {
                                if(each.val().ExhibitionGame.indexOf(e.target.value, 0) !== -1){
                                    eventList.push( Object.assign(each.val(),{id: each.key}));
                                }
                            })
                    // });
                    // setQueryError('')
                    // setQueryEvents(eventList)
                    // setLoader(false);
                    
                    if (eventList.length > 0) {
                        setQueryError('')
                        // setEventsList(searchResults)
                        setQueryEvents(eventList);
                        setLoader(false)
        
                    } else {
                        setQueryEvents([]);
                        setQueryError('No matches found')
                        setLoader(false)
                    }
                // }
                // else {
                //     setQueryEvents([])
                //     setQueryError('No results Found')
                //     setLoader(false);
                // }




            // });
        }
    }

    return (
        <nav className='mt-4 category  mb-4 navbar navbar-expand-lg'>

            <div className="" id="">
                <ul className="navbar-nav ml-auto">
                    {values.map((val) => (
                        <li key={val.text} onClick={() => setActiveId(val.id)} className={`nav-item active ${activeId === val.id ? "active" : "Inactive"}`}>
                            <button  className={`btn btn-clear ${activeId === val.id ? "active" : "Inactive"}`} onClick={handleClickCategory} value={val.text}>{val.text}</button>
                        </li>
                    ))}

                </ul>
            </div>
        </nav>

    );



}

export default Category;