import React, { useEffect, useState } from 'react';
import Header from "../sections/header/header";
import Footer from "../sections/footer/footer";
import Sidebar from "./sidebar";
// import '../../src/css/style.css';
import deposit from "../images/deposit.png";
import Paypal from "./paypal";
import { useTransaction } from "../contexts/transactionContext";
import TransactionCard from "./transactionCard";
import BackButton from "./backButton";
import { useStateValue } from '../contexts/StateProvider';
import { useObject } from "react-firebase-hooks/database";
import firebase from "firebase";
import { auth, db, realTimeDatabase } from "../firebase/firebase";
import { useCollection, useDocumentOnce } from "react-firebase-hooks/firestore"
import TransactionCardLoading from "./transactionCardLoading";
import { ModalComponent } from "../sections/modal/modal";
import { useForm } from "../contexts/formContext";
import RoundCategory from './RoundCategory';
// import Pics from '../images/dummy.png';
import UserImage from '../images/default_profile_photo.svg';
import AddUser from '../images/plus.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory, useParams } from 'react-router-dom';
// import King from '../images/crown.png';

function Tournament({ event, id, key, redirectURL }) {
    let params = useParams();
    // const [{ user, userData }] = useStateValue()
    // const [clickedDeposit, setClickedDeposit] = useState(false)
    // const [amount, setAmount] = useState()
    // const [error, setError] = useState("")
    // const [transactionSnap, loading] = useList(realTimeDatabase.ref('Transaction').orderByChild('userId').equalTo(user?.uid))
    // const { depositSuccess, depositError, setDepositError } = useTransaction()
    // const [modalShow, setModalShow] = useState(false);
    // const { setFormType } = useForm()
    
    const [ParticipentList, setParticipentList] = useState([]);
    const [ScheduleData, setUserData] = useState({});
    const [user] = useAuthState(auth);
    const [rounds, setRounds] = useState([]);
    const [roundWinner, setRoundWinner] = useState({});
    const [roundLastEntry, setRoundLastEntry] = useState({});
    const [ParticipentObj, setParticipentObj] = useState({});
    const [ActiveTab, setActiveTab] = useState('round_1');
    const [Loader, setLoader] = useState(true);
    const [eventDetailsSnap] = useObject(realTimeDatabase.ref(`Exhibitions/${params.eventId}`));
    const { setFormType } = useForm();
    const [modalShow, setModalShow] = useState(false);
    const [messageModalShow, setMessageModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [acceptedRequestParticipats, setAcceptedRequestParticipats] = useState([]);
    const [scheduleDateTimeData, setScheduleDateTimeData] = useState({});
    const [isClutchtimeUser] =useState(user?.email.includes('@clutchtime.com'))
    const history = useHistory();
    // const chatRoomObject = eventSnap?.docs[0];
    // console.log("Event", params.eventId);
    // console.log("Event", eventDetailsSnap.val());
    const difficulty = {
        easy: '#0E7A0D',
        hard: '#d52000',
        veryHard: '#D50000',
        normal: '#D48600',
        playstation: '#006FCD',

    }

    const getData = () => {

        // var acceptedRequestParticipatsArr = [];
        // db.collection("ChatRooms").doc(params.eventId).collection('chat').get().then(async(chatRoomSnap) => {
        //     // setChatRoomSnap(chatRoomSnap.data());
        //     chatRoomSnap.docs.map(async(chatRoomData)=>{
        //         var chatRoom = chatRoomData.data();

        //         if(chatRoom.firstUserTimeProposal && chatRoom.secondUserTimeProposal){
        //             await acceptedRequestParticipatsArr.push(chatRoom.deliveredToParticipants[0]+chatRoom.senderObjId);
        //             await acceptedRequestParticipatsArr.push(chatRoom.senderObjId + chatRoom.deliveredToParticipants[0]);
        //         }
        //     })
        //     await setAcceptedRequestParticipats(acceptedRequestParticipatsArr);
        // })

        db.collection('Schedules').doc(params.eventId).get().then(async (data) => {

            let i = 1;
            let maxRound = 6;
            let roundArr = [];
            let roundWinnerObj = {};

            let ParticipentObject = {};
            let RoundLastEntryObjectId = {};

            while (i <= maxRound) {
                await data.ref.collection(`round_${i}`).get().then(async (subData) => {
                    if (!subData.empty) {
                        let participateUserData = [];
                        let remainingWinnerCnt = 0;
                        let roundLastEntryId = "";
                        subData.docs.map(index => {
                            if (index.data().Winner.trim() == "") {
                                remainingWinnerCnt += 1;
                            }

                            if (roundLastEntryId == "" && index.data().SecondUserName == "") {
                                roundLastEntryId = index.id;
                            }
                            // console.log(`round_${i}`, index.data(), index.id);
                            participateUserData.push({ ...index.data(), docId: index.id });

                        });

                        roundWinnerObj[`round_${i}`] = remainingWinnerCnt;
                        // console.log(roundWinnerObj);
                        roundArr.push(`round_${i}`);
                        setParticipentList(participateUserData);
                        ParticipentObject[`round_${i}`] = participateUserData;
                        RoundLastEntryObjectId[`round_${i}`] = roundLastEntryId;
                    }
                })
                i++;
            }
            await setParticipentObj(ParticipentObject);
            await setRounds(roundArr);
            await setRoundWinner(roundWinnerObj);
            // console.log(roundWinner)
            await setRoundLastEntry(RoundLastEntryObjectId);
            await setLoader(false);

        });
    }

    useEffect(async () => {
        //    console.log('getData')
        getData();
    }, [])

    const handleAddSoreProcess = async (values) => {
        // console.log(values,ActiveTab);
        if (values.FirstUserId.trim() == "") {
            setFormType('messageModal');
            setMessageModalShow(true);
            setErrorMessage("Please select first-user.");
        }
        else if (values.SecondUserId.trim() == "") {
            setFormType('messageModal');
            setMessageModalShow(true);
            setErrorMessage("Please select second-user.");
        }
        else if (values.Winner.trim() != "") {
            setFormType('messageModal');
            setMessageModalShow(true);
            setErrorMessage("You have added score to this user.");
        }
        else {
            await setUserData(values);
            await setFormType('eventScore');
            await setModalShow(true);
            // console.log("here", values, ScheduleData);

        }
    }

    const handleAddSoreDateTimeProcess = async (data,indexOfUser) => {
        if(data.Winner == "" || data.Winner == null){

            if(!data.AgreedDateTime){
                
                if(indexOfUser == "FirstUser" && data.FirstUserId == user.uid){
                    
                    await setScheduleDateTimeData(data);
                    await setFormType('eventScoreDateTime');
                    await setModalShow(true);
                }
                else if(indexOfUser == "SecondUser" && data.SecondUserId == user.uid){
                    
                    await setScheduleDateTimeData(data);
                    await setFormType('eventScoreDateTime');
                    await setModalShow(true);
                }
            }
            else{
                setFormType('messageModal');
                setMessageModalShow(true);
                setErrorMessage("Invitation has accepted.");
            }
        }
        
    }


    // })
    return (
        <>
            <Header />
            <div className={`user container`}>
                <div className={`d-flex `}>
                    <div className={`lg-view`}><Sidebar /></div>
                    <div className='flex-column flex-grow-1'>
                        {/* <div className={`icon-wrapper`}></div> */}
                        <div className='container events body'>
                            {
                                eventDetailsSnap && <div className='card overflow-hidden border-0 grid-item event-card' style={{ minHeight: 'auto', margin: 0 }}>
                                    <div className='card-body'>
                                        <div className='d-flex  align-items-center'>
                                            <p className='card-title'>{eventDetailsSnap.val()?.ExhibitionName}</p>
                                            <p className='cost flex-grow-1 text-right'>${eventDetailsSnap.val()?.ExhibitionEntryFee}</p>
                                        </div>
                                        <p className='card-category'>{eventDetailsSnap.val()?.ExhibitionGame}</p>

                                        <div className=' w-100 card-footer d-flex justify-content-center flex-grow-1 px-0'>
                                            <p className='mr-auto m-0 ml-0 prize text-light'>Prize: ${eventDetailsSnap.val()?.ExhibitionTotalPrizes}</p>
                                            {
                                                eventDetailsSnap.val()?.ExhibitionConsole === 'PlayStation' || eventDetailsSnap.val()?.ExhibitionConsole === 'PlayStation 4' ?
                                                    <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                                        style={{ backgroundColor: difficulty.playstation }} value=''>
                                                        <span>{eventDetailsSnap.val()?.ExhibitionConsole}</span></button>

                                                    :
                                                    eventDetailsSnap.val()?.ExhibitionConsole === 'Xbox One' ?
                                                        <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                                            style={{ backgroundColor: difficulty.easy }} value=''>
                                                            <span>{eventDetailsSnap.val()?.ExhibitionConsole}</span></button>

                                                        :
                                                        eventDetailsSnap.val()?.ExhibitionConsole === 'Xbox' ?
                                                            <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                                                style={{ backgroundColor: difficulty.easy }} value=''>
                                                                <span>{eventDetailsSnap.val()?.ExhibitionConsole}</span></button>

                                                            :
                                                            <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                                                style={{ backgroundColor: difficulty.hard }} value=''>
                                                                <span>{eventDetailsSnap.val()?.ExhibitionConsole}</span></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !!rounds && eventDetailsSnap && <RoundCategory activeTab={ActiveTab} roundWinner={roundWinner} event={rounds} onChangeTab={(item) => { setActiveTab(item); }} exhibition={eventDetailsSnap.val()} />
                            }
                            <div className='mobile-view-card'>
                                <div className='w-100 d-flex flex-row pb-sm-4'>
                                    <h5 className='text-light text-left'>Participent</h5>
                                </div>
                                <div className='card-parent d-flex flex-wrap'>
                                    {
                                        !Loader ?
                                            ParticipentObj[ActiveTab] ?
                                                (ParticipentObj[ActiveTab] || []).map((data, key) => {
                                                    return (
                                                        (isClutchtimeUser) ?
                                                            <div className='card-custom flex-grow-0'>
                                                                <div className='card-body bg-dark d-flex flex-row justify-content-around' onClick={() => { handleAddSoreProcess(data) }} style={{ borderRadius: '15px', cursor: 'pointer' }}>
                                                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                                                        <div className='d-flex w-100 justify-content-center align-items-center'>
                                                                            {(data?.FirstUserScore > data?.SecondUserScore) && <div className={'bg-view1'} />}
                                                                        </div>
                                                                        <div className='userProfileImage d-flex' style={{ borderWidth: '2px', borderStyle: data?.FirstUserId ? 'solid' : 'dashed' }}>
                                                                            {
                                                                                data?.FirstUserId ?
                                                                                    <img src={!!data?.FirstProfileImageUrl && data?.FirstProfileImageUrl !== "NONE" ? data.FirstProfileImageUrl : UserImage} className='w-100 h-100' alt='' />
                                                                                    :
                                                                                    <img src={AddUser} style={{ width: '30px', height: '30px', alignSelf: 'center' }} alt='' />
                                                                            }
                                                                        </div>
                                                                        <div className='mt-2 text-light justify-content-center align-items-center'>
                                                                            <div className='space-medium text-capitalize text-center text-truncate' style={{ maxWidth: '86px', fontSize: '0.8rem' }}>{data?.FirstUserName || ''}</div>
                                                                            <div className="space-sm text-lowercase text-muted text-center text-truncate" style={{ maxWidth: '86px', fontSize: '0.7rem' }}>{data?.FirstUserName ? '@' + data.FirstUserName : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                                                        <h4 className='text-xl-center text-center text-light text-uppercase'>VS</h4>
                                                                    </div>
                                                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                                                        <div className='d-flex w-100 justify-content-center align-items-center'>
                                                                            {(data?.SecondUserScore > data?.FirstUserScore) && <div className={'bg-view1'} />}
                                                                        </div>
                                                                        <div className='userProfileImage d-flex' style={{ borderWidth: '2px', borderStyle: data?.SecondUserId ? 'solid' : 'dashed' }}>
                                                                            {
                                                                                data?.SecondUserId ?
                                                                                    <img src={!!data?.SecondProfileImageUrl && data?.SecondProfileImageUrl !== "NONE" ? data.SecondProfileImageUrl : UserImage} className='w-100 h-100' alt='' />
                                                                                    :
                                                                                    <img src={AddUser} style={{ width: '25px', height: '25px', alignSelf: 'center' }} alt='' />
                                                                            }
                                                                        </div>
                                                                        <div className='mt-2 text-light justify-content-center align-items-center'>
                                                                            <div className='space-medium text-capitalize text-center text-truncate' style={{ maxWidth: '86px', fontSize: '0.8rem' }}>{data?.SecondUserName || ''}</div>
                                                                            <div className="space-sm text-lowercase text-muted text-center text-truncate" style={{ maxWidth: '86px', fontSize: '0.7rem' }}>{data?.SecondUserName ? '@' + data.SecondUserName : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='card-custom flex-grow-0'>
                                                                <div className='card-body bg-dark d-flex flex-row justify-content-around' style={{ borderRadius: '15px' }}>
                                                                    <div className="d-flex flex-column justify-content-center align-items-center pointer" onClick={() => { handleAddSoreDateTimeProcess(data, "FirstUser") }}>
                                                                        <div className='d-flex w-100 justify-content-center align-items-center'>
                                                                            {(data?.FirstUserScore > data?.SecondUserScore) && <div className={'bg-view1'} />}
                                                                        </div>
                                                                        <div className='userProfileImage d-flex' style={{ borderWidth: '2px', borderStyle: data?.FirstUserId ? 'solid' : 'dashed' }}>
                                                                            {
                                                                                data?.FirstUserId ?
                                                                                    <img src={!!data?.FirstProfileImageUrl && data?.FirstProfileImageUrl !== "NONE" ? data.FirstProfileImageUrl : UserImage} className='w-100 h-100' alt='' />
                                                                                    :
                                                                                    <img src={AddUser} style={{ width: '30px', height: '30px', alignSelf: 'center' }} alt='' />
                                                                            }
                                                                        </div>
                                                                        <div className='mt-2 text-light justify-content-center align-items-center'>
                                                                            <div className='space-medium text-capitalize text-center text-truncate' style={{ maxWidth: '86px', fontSize: '0.8rem' }}>{data?.FirstUserName || ''}</div>
                                                                            <div className="space-sm text-lowercase text-muted text-center text-truncate" style={{ maxWidth: '86px', fontSize: '0.7rem' }}>{data?.FirstUserName ? '@' + data.FirstUserName : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                                        <h4 className='text-xl-center text-center text-light text-uppercase'>VS</h4>
                                                                    </div>
                                                                    <div className="d-flex flex-column justify-content-center align-items-center pointer" onClick={() => { handleAddSoreDateTimeProcess(data, "SecondUser") }}>
                                                                        <div className='d-flex w-100 justify-content-center align-items-center'>
                                                                            {(data?.SecondUserScore > data?.FirstUserScore) && <div className={'bg-view1'} />}
                                                                        </div>
                                                                        <div className='userProfileImage d-flex' style={{ borderWidth: '2px', borderStyle: data?.SecondUserId ? 'solid' : 'dashed' }}>
                                                                            {
                                                                                data?.SecondUserId ?
                                                                                    <img src={!!data?.SecondProfileImageUrl && data?.SecondProfileImageUrl !== "NONE" ? data.SecondProfileImageUrl : UserImage} className='w-100 h-100' alt='' />
                                                                                    :
                                                                                    <img src={AddUser} style={{ width: '25px', height: '25px', alignSelf: 'center' }} alt='' />
                                                                            }
                                                                        </div>
                                                                        <div className='mt-2 text-light justify-content-center align-items-center'>
                                                                            <div className='space-medium text-capitalize text-center text-truncate' style={{ maxWidth: '86px', fontSize: '0.8rem' }}>{data?.SecondUserName || ''}</div>
                                                                            <div className="space-sm text-lowercase text-muted text-center text-truncate" style={{ maxWidth: '86px', fontSize: '0.7rem' }}>{data?.SecondUserName ? '@' + data.SecondUserName : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    )
                                                })
                                                :
                                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                                    <p className='text-center'>Data Not Found</p>
                                                </div>
                                            :
                                            <div className='w-100 d-flex justify-content-center align-items-center'>
                                                <div class=" spinner-border" role="status" style={{ color: '#18ff00' }}>
                                                    <span class="sr-only text-center">Loading Please Wait ...</span>
                                                </div>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                        {
                            (isClutchtimeUser) ?
                                <ModalComponent
                                    scheduleDatas={ScheduleData}
                                    show={modalShow}
                                    onHide={() => { setModalShow(false); getData(); }}
                                    eventId={params.eventId}
                                    activeTab={ActiveTab}
                                    eventData={eventDetailsSnap?.val()}
                                    roundWinner={roundWinner}
                                    roundLastEntry={roundLastEntry}
                                    ParticipentObj={ParticipentObj}
                                />
                                :
                                <ModalComponent
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    scheduleData={scheduleDateTimeData}
                                    eventId={params.eventId}
                                    activeTab={ActiveTab}
                                    eventData={eventDetailsSnap?.val()}
                                />
                        }

                        <ModalComponent show={messageModalShow} onHide={() => setMessageModalShow(false)} title="Add Score" type="error" errorMessage={errorMessage} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Tournament;