import {db, realTimeDatabase} from "../firebase/firebase";

// Add documents to a collection
export const pushData = async (path,data,id) =>{

  await db.collection(path).doc(id).set(data).catch(e => console.log(e))
  await realTimeDatabase.ref(path+'/'+id).set(data).catch(e => console.log(e))
}

export const setRealtimeDoc = async (path,data,id) =>{

  await realTimeDatabase.ref(path+'/'+id).set(data).catch(e => console.log(e))
}

export const setFirestoreDocument = async (path,data,id) =>{

  await db.collection(path).doc(id).set(data).catch(e => console.log(e))
}

export const pushRealTimeData = (path, data) => {
  return realTimeDatabase.ref(path).push(data)
}

// Get documents from a collection or subcollection
export const getRealtimeDoc = (path,id) =>{


    return realTimeDatabase.ref(path+'/'+id).once("value")
}
export const getRealtimeChild =  (path,child,id) =>{

    return  realTimeDatabase.ref(path).orderByChild(child).equalTo(id)
}
export const getDocWithRef =  (path,ref) =>{

    return  realTimeDatabase.ref(path +'/'+ ref)
}
export const getDoc = (collection,field,value) =>{
    let collectionList = []
    db.collection(collection).where(field, "==", field).get()
        .then((snapshot) => {
            snapshot.forEach(doc =>{
                const data = doc.data()
                collectionList.push(data)

            })



        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
    // console.log(collectionList)

    return collectionList
}

// Update document fields
export const updateDocument =  (collection,id,data) =>{
    return db.collection(collection).doc(id).update(data).then(()=>{

      return realTimeDatabase.ref(collection+'/'+id).update(data)
    })
}
export const updateFirebaseDocument =  (collection,id,data) =>{
 return  realTimeDatabase.ref(collection+'/'+id).update(data)


}
export const updateFirestoreDocument =  (collection,id,data) =>{
    return db.collection(collection).doc(id).update(data)


}

export function timeConverter(UNIX_timestamp,type){
  var a = new Date(UNIX_timestamp );
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var monthNum = a.getUTCMonth();
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  // var sec = a.getSeconds()
  var time;
  type === 'H-M'?
     time = hour + ':' + min
    :
    type === 'D-M-Y'
        ?
    time = date + ' ' + month + ' ' + year + ' '
    :
        type === 'D/M/Y'
            ?
            time = date + '/' + monthNum + '/' + year
            :
    time = date + ' ' + month + ' ' + year + ' '



  return time;
}

export function checkDate(UNIX_timestamp){
  var a = new Date(UNIX_timestamp );
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time;

    time = date + ' ' + month + ' ' + year + ' '


  return time;
}



