import React from 'react';
import {NavLink} from "react-router-dom";
import {useChat} from "../contexts/messageContext";
import {useStateValue} from "../contexts/StateProvider";

function MobileNavbar() {
    const [{user}] = useStateValue()

    const {setShow} = useChat();


    return (
        <div className='mobile-navbar  p-2 shadow-lg'>
            <NavLink  activeClassName="active"  exact={true} to={'/events'} className='flex-column text-center flex-grow-1'>
                <svg  xmlns="http://www.w3.org/2000/svg" width="60" height="62.442" viewBox="0 0 60 62.442">
                    <path activeClassName="active" className={`svg`} id="Path_27" data-name="Path 27" d="M70,66.667A3.333,3.333,0,0,1,66.667,70H13.333A3.333,3.333,0,0,1,10,66.667V31.633A3.333,3.333,0,0,1,11.287,29L37.953,8.26a3.333,3.333,0,0,1,4.093,0L68.713,29A3.333,3.333,0,0,1,70,31.633V66.667Zm-6.667-3.333V33.26L40,15.113,16.667,33.26V63.333Z" transform="translate(-10 -7.558)" />
                </svg>

                <span>Home</span></NavLink>
            <NavLink  activeClassName="active"  exact={true} to={'/messages'} onClick={() => setShow(false)} className='flex-column text-center flex-grow-1'>
                <svg xmlns="http://www.w3.org/2000/svg" width="66.667" height="60" viewBox="0 0 66.667 60">
                    <path activeClassName="active" id="Path_29" data-name="Path 29" d="M6.667,29.98a19.967,19.967,0,0,1,20-19.98H53.333a20.016,20.016,0,0,1,20,19.98V70H26.667a20.016,20.016,0,0,1-20-19.98Zm60,33.353V29.98A13.347,13.347,0,0,0,53.333,16.667H26.667A13.3,13.3,0,0,0,13.333,29.98V50.02A13.347,13.347,0,0,0,26.667,63.333Zm-20-26.667h6.667v6.667H46.667Zm-20,0h6.667v6.667H26.667Z" transform="translate(-6.667 -10)" />
                </svg>

                <span>Message</span></NavLink>
            <NavLink  activeClassName="active"  exact={true} to={`/user/${user && user.uid}`} className='flex-column text-center flex-grow-1'>
                <svg xmlns="http://www.w3.org/2000/svg" width="57.377" height="65.987" viewBox="0 0 57.377 65.987">
                    <path activeClassName="active"  id="Path_31" data-name="Path 31" d="M40,56.667c12.207,0,22.883,5.25,28.69,13.083l-6.14,2.9c-4.727-5.6-13.06-9.32-22.55-9.32s-17.823,3.72-22.55,9.32l-6.137-2.907C17.12,61.913,27.793,56.667,40,56.667Zm0-50A16.667,16.667,0,0,1,56.667,23.333v10a16.666,16.666,0,0,1-15.942,16.65L40,50A16.667,16.667,0,0,1,23.333,33.333v-10A16.666,16.666,0,0,1,39.276,6.683Zm0,6.667a10,10,0,0,0-9.983,9.414L30,23.333v10a10,10,0,0,0,19.983.587L50,33.333v-10a10,10,0,0,0-10-10Z" transform="translate(-11.313 -6.667)" />
                </svg>

                <span>Profile</span></NavLink>


        </div>


    );
}

export default MobileNavbar;