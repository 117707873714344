import firebase from "firebase";
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

// Initialize Firebase
// Save as environment variables
const app = firebase.initializeApp({
                apiKey: "AIzaSyBZs927MPc1kJHShPSEOhy5RndEQh9Y9Ik",
  				authDomain: "clutchtime-4daa8.firebaseapp.com",
  				databaseURL: "https://clutchtime-4daa8.firebaseio.com",
				projectId: "clutchtime-4daa8",
  				storageBucket: "clutchtime-4daa8.appspot.com",
  				messagingSenderId: "271080096881",
				appId: "1:271080096881:web:625f412c4aceb399bb87d0",
				measurementId: "G-EWLYHN12W1"
});

const db = app.firestore()
const realTimeDatabase = app.database();
const auth =app.auth()
const storage =app.storage()
 export {db , auth ,realTimeDatabase,storage}