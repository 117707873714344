import React, { useEffect, useRef, useState } from 'react';
import basketball from '../images/basketball.png'
import { useHistory } from 'react-router-dom'
import football from '../images/Football.png'
import tennis from '../images/tennis.png'
import { realTimeDatabase } from "../firebase/firebase";
import logo from '../images/logo.png';
import { db } from "../firebase/firebase";

const difficulty = {
    easy: '#0E7A0D',
    hard: '#d52000',
    veryHard: '#D50000',
    normal: '#D48600',
    playstation: '#006FCD',

}

function prizeDefinition(event){
	let prizeString = "";
	const currentParticipants = event.ExhibitionCurrentParticipants;
	const maximumParticipants = event.ExhibitionMaximumParticipants;
	if(currentParticipants < maximumParticipants){
	   prizeString = "Projected Total Prize: ";
	   }else{
		   prizeString = "Prize: ";
	   }
	return prizeString;
}


function Card({ event, id, redirectURL }) {

    const history = useHistory()
    const idRef = useRef()
    const [participants, setParticipants] = useState([])
    const [loading, setLoading] = useState(true)
    const [game, setGame] = useState({});
    const [gameCoverArt, setGameCoverArt] = useState(null);

    let participantsList = []

    // This function is called when the Card component is clicked
    function handleClick() {
        if (!!redirectURL) return history.push(redirectURL);
        return history.push(`/eventDetails/${idRef.current.id}`);
    }
    useEffect(() => {
        if (id !== undefined) {

            // Fetch last 4 participants matching the id prop
            realTimeDatabase.ref("Participants")
                .orderByChild("ExhibitionId")
                .equalTo(id)
                .limitToLast(4)
                .get()
                .then(snapshot => {

                    snapshot.forEach(doc => {
                        // Append the doc object to participantsList array
                        participantsList.push(doc.val())
                    })
                    setParticipants(participantsList)
                    setLoading(false)



                })
                .catch(e => {
                    console.log(e.message)
                    setLoading(false)
                })

            return () => null

        }
        return () => null

    }, [event.ExhibitionCurrentParticipants])

    useEffect(() => {
        if(event){
            if(!event.ExhibitionGameCoverArt || event.ExhibitionGameCoverArt == ""){
                db.collection('Games').where('Game', '==', event?.ExhibitionGame).get().then((gameRes)=>{
                    if(gameRes.docs.length > 0){
                        var gameData = gameRes.docs[0].data();
        
                        if("gameCoverArt" in gameData && gameData.gameCoverArt != ""){
                            setGameCoverArt(gameData.gameCoverArt);
                        }
                        else{
                            setGameCoverArt(logo);
                        }
        
                        setGame(gameData);
                    }
                    else{
                        setGameCoverArt(logo);
                    }
                    // console.log("Game++++++++++++++++", );
                }).catch((e)=>{
                    console.log("error in Get Games++", e);
                    setGameCoverArt(logo);
                })
            }
            else{
                setGameCoverArt(event.ExhibitionGameCoverArt);
            }
        }
    }, [event])

    return (
        <div onClick={handleClick} ref={idRef} id={id || event.id}
            // className={'card card-event bg-warning'}
            className={`card ${!event && 'loading'} pointer overflow-hidden border-0 grid-item event-card`}
        >

            <div className='cover-img-wrapper' style={{height: "14rem"}}>
                <img className='h-100'
                    src={gameCoverArt} alt="" style={{width: "100%"}}/>
            </div>
            <div className='card-body'>
                <div className='d-flex  align-items-center'>
                    <p className='card-title'>{event?.ExhibitionName}</p>
                    <p className='cost flex-grow-1 text-right '>${event?.ExhibitionEntryFee?.toFixed(2)}</p>
                </div>
                <p className='card-category'>
                    {event?.ExhibitionGame}
                </p>
                <div className='d-flex flex-grow-1 justify-content-between'>

                    <div className='thumbnail-list d-flex flex-row'>
                        <>
                            {
                                !loading && participants ? participants.map((image, index) => {
                                    return (


                                        <>

                                            {image.userProfileImageUrl !== undefined && image.userProfileImageUrl !== null &&
                                                <li key={index} className='thumbnail-wrapper'><img
                                                    style={{ height: '100%', width: 'auto', maxWidth: 'auto' }}
                                                    src={image.userProfileImageUrl} alt="" />
                                                </li>}

                                        </>)
                                })
                                    : <div className={`thumbnail-wrapper animated-background`}>
                                    </div>}
                        </>
                    </div>
                    <p className='spots' style={{left:0}}> +{parseInt(event?.ExhibitionCurrentParticipants) > 0 ? event?.ExhibitionCurrentParticipants : 0} going
                        ({parseInt(event?.ExhibitionMaximumParticipants) - parseInt(event?.ExhibitionCurrentParticipants)} {parseInt(event?.ExhibitionMaximumParticipants) - parseInt(event?.ExhibitionCurrentParticipants) === 1 ? 'spot ' : 'spots  '}
                        left)</p>

                </div>

                <div className={` w-100 card-footer d-flex justify-content-center flex-grow-1`}>
                    <p className='m-0 prize text-light'>
						{prizeDefinition(event)} ${event?.ExhibitionTotalPrizes}
                    </p>
                    {
                        event?.ExhibitionConsole === 'PlayStation' || event?.ExhibitionConsole === 'PlayStation 4' ?
                            <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                style={{ backgroundColor: difficulty.playstation }} value=''>
                                <span>{event?.ExhibitionConsole}</span></button>

                            :

                            event?.ExhibitionConsole === 'Xbox One' ?
                                <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                    style={{ backgroundColor: difficulty.easy }} value=''>
                                    <span>{event?.ExhibitionConsole}</span></button>

                                :
                                event?.ExhibitionConsole === 'Xbox' ?
                                    <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                        style={{ backgroundColor: difficulty.easy }} value=''>
                                        <span>{event?.ExhibitionConsole}</span></button>

                                    :
                                    <button className='ml-auto mr-0  text-light btn-danger difficulty-btn '
                                        style={{ backgroundColor: difficulty.hard }} value=''>
                                        <span>{event?.ExhibitionConsole}</span></button>

                    }
                </div>
            </div>
        </div>
    );
}


export default Card;
