import React, { useRef, useState, useEffect } from 'react';
import './signup.css';
import { pushData, updateDocument, setRealtimeDoc, setFirestoreDocument } from "../../utility/helper";
import firebase from "firebase";
import { auth, db } from '../../firebase/firebase';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuthMode } from "../../contexts/authModeContext";
import PuffLoader from "react-spinners/PuffLoader";
import {css} from "@emotion/react";

function ReferralCode(props) {

    const {blockMode, setBlockMode} = useAuthMode()
    
    const referralCode = useRef();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [error, setError] = useState("")
    let history = useHistory();
    const {email, password, username, gamerTagData} = props?.referralCodeData;

    const override = css`
      display: block;
      margin: 0 7%;
      margin-left: auto;
      margin-right: auto;`;
    let [color, setColor] = useState('');
    // const [registeredUserId, setRegisteredUserId] = useState("");

    async function Cancel(){

        if(submitLoading || cancelLoading){
            return "";
        }
        
        setColor("#18ff00");
        setCancelLoading(true);
        setBlockMode(true)

        await SaveUser();
        await setCancelLoading(false);
        props.onHide(true);
        await auth.signOut();
        auth.signInWithEmailAndPassword(email, password).then(async(user)=>{
            await setBlockMode(false);
            history.push(`/user/${user.user.uid}`);
            
        }).catch((err)=>{
            console.log("Login Error:+++++++++", err);
        });
    }

    async function Submit(){

        if(submitLoading || cancelLoading){
            return "";
        }
        
        setColor("#ffffff");
        setSubmitLoading(true);
        setBlockMode(true)
        try{
            setError('');
            var referral_code_value = referralCode.current.value.trim();
            if(referral_code_value == ""){
                setError('Please enter Referral-Code');
                setSubmitLoading(false);
                return ""
            }
            
            const users = await db.collection('User').get();
            
            var referral_code_matched = false;
            var inviterUser = "";
            users.forEach(user => {
                const usrData = user.data();

                if(usrData && "userReferralCode" in usrData){

                    if (usrData.userReferralCode == referral_code_value) {
                        referral_code_matched = true;
                        inviterUser = usrData;
                    }
                }
            })

            if(!referral_code_matched){
                setError('We did not find that code, please reenter a valid code');
                setSubmitLoading(false);
                return "";
            }

            const registeredUserId = await SaveUser();
            
            if(registeredUserId && registeredUserId != ""){

                const invites = await db.collection('Invites').get();
                
                var invite_exists = false;
                invites.forEach(invite => {
                    const inviteData = invite.data();
    
                    if(inviteData && "InviteeId" in inviteData){
    
                        if (inviteData.InviteeId == registeredUserId) {
                            invite_exists = true;
                        }
                    }
                });
    
                if(invite_exists){
                    setError('You have already entered an invite code');
                    setSubmitLoading(false);
                    return "";
                }
    
                var invitesObj = {
                    InviterId: inviterUser.objectId,
                    InviteeId: registeredUserId,
                    JoinedEventDateTimeStamp: null,
                    JoinedEventID: null
                }   
    
                await db.collection('Invites').add(invitesObj).then(async()=>{
                    props.onHide(true);
                    setSubmitLoading(false);
                    await auth.signOut();
                    
                    auth.signInWithEmailAndPassword(email, password).then(async(user)=>{
                        await setBlockMode(false);
                        history.push(`/user/${user.user.uid}`);
                        
                    }).catch((err)=>{
                        console.log("Login Error:+++++++++", err);
                    });
                }).catch((e)=>{
                    console.log("Error to Save Invites+++++", e);
                    setSubmitLoading(false);
                    setError("Something went wrong, Please try again later");
                });
            }
        }
        catch (err) { 
            console.warn('Error in On submit function :-', err) 
            setError("Something went wrong, Please try again later");
        }
    }

    async function SaveUser() {
        
        return auth.createUserWithEmailAndPassword(email, password)
            .then(userInfo => {
                const uuid = uuidv4().replace(/-/g, '').toUpperCase();
                const userReferralCode = uuid+username;
                const userObj = {
                    FollowerIds: [],
                    FollowersIds: [],
                    gamerTag: username,
                    userName: username,
                    userLosses: 0,
                    userWins: 0,
                    userRank: 'Prospect',
                    userProfileImageUrl: "NONE",
                    updateProfileAvatarDeliveredTo: [],
                    email: userInfo.user.email,
                    pushId: '',
                    isOnline: true,
                    followedUsersIds: [],
                    contact: [],
                    blockedUserId: [],
                    userBalance: 0,
                    objectId: userInfo.user.uid,
                    notificationCounts: 0,
                    loginMethod: 'email',
                    twitchlink: "NONE",
                    youtubelink: "NONE",
                    userPictureId: false,
                    userPlayStationOption: true,
                    userXboxOption: true,
                    userReferralCode: userReferralCode,
                    ...gamerTagData
                };

                const userObjRealTimeDB = {
                    isOnline: true,
                    loginMethod: 'email',
                    notificationCounts: 0,
                    objectId: userInfo.user.uid,
                    pushId: '',
                    userBalance: 0,
                    userEmail: userInfo.user.email,
                    userGamerTag: username,
                    userLosses: 0,
                    userPictureId: false,
                    userPlayStationOption: true,
                    userProfileImageUrl: "NONE",
                    userRank: 'Prospect',
                    userWins: 0,
                    userXboxOption: true,
                    userTwitchChannelLink: '',
                    userYouTubeChannelLink: '',
                    email: userInfo.user.email,
                    gamerTag: username,
                    followedUsers: [],
                    twitchlink: 'NONE',
                    youtubelink: 'NONE',
                    userName: username,
                    userReferralCode: userReferralCode,
                    ...gamerTagData
                };

                var user = firebase.auth().currentUser;
                
                return user.updateProfile({
                    displayName: username,
                    photoURL: 'https://firebasestorage.googleapis.com/v0/b/fantasysports-7117e.appspot.com/o/default_profile_photo.svg?alt=media&token=d217e767-41e1-4a94-9f71-4a60b99b3403'
                }).then(async () => {
                    
                    userObj.userProfileImageUrl = user.photoURL;
                    //Update user Info
                    await setFirestoreDocument('User', userObj, userInfo.user.uid)
                    await setRealtimeDoc('Users', userObjRealTimeDB, userInfo.user.uid)
                    return userInfo.user.uid;
                })

            })
            .catch(err => {
                setSubmitLoading(false)

                switch (err.code) {

                    case 'auth/email-already-in-use':
                        setError('Email already exists')
                    case 'auth/invalid-email':
                        setError(err.message)
                        break;
                    case 'auth/weak-password':
                        setError(err.message)
                        break;
                 }

                 return "";
            })
    }

    return (
        <>
        <p className='form-title text-sm-left text-lg-center'>Referral Code</p>
        <p className="text-danger">{error}</p>
        <form className="form event-form">

            <div className="input-group">

                <input name="ReferralCode" ref={referralCode} required type="text" placeholder="Referral Code"/>

            </div>
            <div className='d-flex flex-row justify-content-between align-item-center'>
                <button  style={{ width: '42%', maxHeight: '36px', borderRadius: '10px', background: '#ffffff', textTransform: 'capitalize' }} type='button' onClick={Cancel}>
                    <span className='form-btn' style={{fontSize: "10pt"}}>{(cancelLoading) ? <PuffLoader css={override}  loading={cancelLoading} size={24} color={color} /> : 'I do not have a code'}</span>
                </button>
                <button style={{ width: '42%', maxHeight: '36px', borderRadius: '10px', textTransform: 'capitalize' }} type='button' onClick={Submit}>
                    <span className='form-btn' style={{fontSize: "10pt"}}>{(submitLoading) ? <PuffLoader css={override}  loading={submitLoading} size={24} color={color} /> : 'Submit referral code'}</span>
                </button>
            </div>
        </form>
        </>
    );
}

export default ReferralCode;


