import React, {useRef, useState} from 'react';
import './login.css';
import trophy from '../../images/trophy.svg';
import password from '../../images/password.svg';
import mail from '../../images/mail.svg';
import {useHistory} from 'react-router-dom'
import {auth} from '../../firebase/firebase';
import {useStateValue} from '../../contexts/StateProvider';
import logo from '../../images/logo.png';



export default function Login(props) {
    const history = useHistory()
    const [ dispatch] = useStateValue()
    
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const login = async (e) => {
        e.preventDefault()
        try {
            setError('')
            setLoading(true)
            // Login user
            await auth.signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value)
                .then((user) => {
                    setLoading(false)
                    
                    if(props && "email" in props){

                        history.push(`/webview/user/${user.user.uid}/manage-funds`);
                    }
                    else{
                        history.push(`/user/${user.user.uid}`);
                    }
                    
                    dispatch({
                        type: "SET_USER",
                        user
                    })


                })

        } catch (err) {
            setLoading(false)

            switch (err.code) {
                case 'auth/invalid-email':
                    setError(err.message)

                case 'auth/user-disabled':
                    setError('Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.')
                case 'auth/user-not-found':
                    setError("Account doesn't exist")
                    break;
                case 'auth/wrong-password':
                    setError("Password entered is wrong")
                    break;
            }

        }
    }

    return (
        <>
        <form className="form" onSubmit={login}>
            <div className={`header-content `}>
                <img className="form-image" src={logo} alt=""/>
                <p className="form-title pt-4">ClutchTime</p>
                <p className='form-text f-18'>Please register your details to continue
                    with ClutchTime</p>
            </div>
            <p className="text-danger">{error}</p>

            <div className="input-group">
                <input ref={emailRef} name="email" style={{backgroundImage: `url(${mail})`}} type="Email"
                       placeholder="Email" defaultValue={props?.email}/>
                <input ref={passwordRef} name="password" style={{backgroundImage: `url(${password})`}}
                       type="Password" placeholder="Password"/>

            </div>

            <button disabled={loading} style={{background : loading ? '#ffffff':''}}  type="submit"><span className='form-btn'>Login</span></button>
        </form>
</>
    );
}
