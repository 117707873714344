import React from 'react';

function MessageCardLoading() {
    return (
        <>
            <div className={`d-flex w-100 pointer loading mb-2 user-list-sub-section`}>
                <div className='user-list-thumb-wrapper'></div>
                <div className='ml-3 text-light w-100'>
                    <span></span>
                    <span className=' float-right d-flex flex-column align-items-center justify-contents-center space-light'>
                                                                <small></small>
                                                                    </span>
                    <div className="space-light text-break"></div>
                </div>
            </div>
        </>
    );
}

export default MessageCardLoading;