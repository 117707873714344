import React, {useContext, useState} from 'react'

const ChatContext = React.createContext()

export function useChat() {
    return useContext(ChatContext)
}

export function ChatProvider({ children }) {

    const [show,setShow] = useState(false);


    const value = {

        show,setShow

    }


    return (
        <ChatContext.Provider value={value}>
            { children}
        </ChatContext.Provider>
    )
}
