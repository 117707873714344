import React, {useContext, useState} from 'react'

const AuthModeContext = React.createContext()

export function useAuthMode() {
    return useContext(AuthModeContext)
}

export function AuthModeProvider({ children }) {
  const [blockMode, setBlockMode] = useState(false)

    const value = {
        blockMode,
        setBlockMode,
    }

    return (
        <AuthModeContext.Provider value={value}>
            { children}
        </AuthModeContext.Provider>
    )
}
