import React from 'react';


function EventOptions({eventOption}) {

    return (


            <>
                { eventOption && eventOption && eventOption.map( o => {return(<option  value={o}>{o}</option>)}) }


        </>



    );
}

export default EventOptions;